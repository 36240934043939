import React from 'react'
import Navbar2 from '../Navbar/Navbar2'
import KidsRide from '../LandRide/KidsRide/KidsRide'
import LatestOffer from '../LatestOffer/LatestOffer'
import Footer from '../Footer/Footer'
import KidsBannerVideo from './KidsBannerVideo/KidsBannerVideo'

const Kids = () => {
  return (
    <div>
      <Navbar2 />
      <KidsBannerVideo />
      <KidsRide />
      <LatestOffer />
      <Footer />
    </div>
  )
}

export default Kids
