import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function LandRideThrillOne() {
	return (
		<div>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div className="container" style={{width:"80%", paddingTop:"30px"}}>
                <div className="section-heading mb-4">
                    {/* <span className="sub-title">Intructions</span> */}
                    <h2 className="h1 mb-0" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>LAND THRILL RIDES</h2>
                </div>
                <div className="row position-relative mt-n1-9">
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/pendulum-2.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className="mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Pendulum</h3>
                                {/* <span className="font-weight-600 text-secondary">Web Designer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/pendulum" className="text-white">About Pendulum</Link></h3>
                                        <p className="text-white mb-0">The Pendulum rotates you clockwise and anticlockwise in the sky, as though you are a part of a giant clock and the clock has struck a solid hour and you are on the pendulum to indicate the hour.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/pendulum" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/zip-line.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Zip Line</h3>
                                {/* <span className="font-weight-600 text-secondary">Photographer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/zipline" className="text-white">About Zip Line</Link></h3>
                                        <p className="text-white mb-0">Zip line is a thrilling recreation that connects a zip like cable from one end to another end at different elevations. It gives you the feel being free in the sky</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/zipline" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/gyro-ride.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info" >
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Gyro Ride</h3>
                                {/* <span className="font-weight-600 text-secondary">Psychologist</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/gyroride" className="text-white">About Gyro Ride</Link></h3>
                                        <p className="text-white mb-0">The gyro ride will twist you, turn you, spin and whirl you as though you are in space without gravity.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/gyroride" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
export default LandRideThrillOne;

