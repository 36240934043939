import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import JumboTrainSlider from './JumboTrainSlider'



const  JumboTrain = () => {
  return (
    <div>
      <Navbar2 />
      <JumboTrainSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>JUMBO TRAIN</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Jumbo Train is a miniature train ride for the mini members of the family, the kids.
        <br />
        This electric train has four cabins and travels on an oval shaped track at a comfortable speed.
        <br />
        It takes your kid on a ride that gives them a calm whirl around the track, as they enjoy the fun of the ride.
        <br />
        Let them climb on this amazing journey with the play buddies as they gather wonderful bundles of memories for them to cherish for life.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default JumboTrain

