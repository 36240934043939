import React from 'react'

const Safety = () => {
  return (
    <div>
    <div className="container posts-content">
    <div className="row">
    <div className="col-lg-3">
            <div className="card mb-4" style={{border:"none"}}>
              <div className="card-body">
                <div className="media mb-3">
                  <div class="media-body ml-3">
                    <h2 className="cardOneHeading" style={{color:'#082B5C',fontFamily:'Comic Sans MS',fontSize:"28px", textAlign:'left', fontWeight:"600"}}>SAFETY</h2>
                    <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "4px", borderColor:"#FED001", marginTop:"8px"}} />
                    <img src='/Images/caution.jpg' alt='safety' style={{marginTop:"5px"}}/>
                  </div>
                </div>
            
                <p>
                  <ul style={{textAlign:"left"}}>
                    <li>Floor slippery when wet pool surface might be slippery do not run</li>
                  </ul>
                </p>
              </div>

            </div>
        </div>
        <div className="col-lg-9">
            <div className="card mb-4" style={{border:"none"}}>
              <div className="card-body">
                <div className="media mb-3">

                </div>
            

                <div style={{display:"flex", justifyContent:"center", position:"relative"}}>
                  <div>
                  <h4 style={{textAlign:"left"}}>Wild Waters is filled with fun rides and attractions, and following these simple tips can help you ensure that your visit is as safe & fun as it can be.</h4>
                    <ul style={{textAlign:"left"}}>
                    <li>Wear comfortable clothing and shoes. Light-colored clothes are best to help reflect the sun.</li>
                    <li>Wear UV protective sunglasses and sunscreen with SPF15 or higher.</li>
                    <li>Don’t wait too late to hydrate. Drink water before you get going and before you get thirsty.</li>
                    <li>If you are visiting with a child make sure he/she knows how to contact you if they get lost. Put a note in his/her pocket with phone numbers and names to contact.</li>
                    <li>Maintain discipline in ride queue area and don’t jump the cue. It will make sure that everyone enjoys the ride equally well.</li>
                    <li>Pools are shallow. Please do not dive into pools.</li>
                    <li>Please avoid pushing one another in the ride areas or inside the park in general. It’s dangerous for everyone.</li>
                    <li>Pregnant Women, heart patients, persons with high blood pressure and those prone to epilepsy are advised to avoid fast/adventurous rides.</li>
                    <li>Please follow safety instructions of lifeguards, ride operators and instructions displayed near the rides.</li>
                  </ul>
                  </div>

                </div>
              </div>

            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Safety
