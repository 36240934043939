import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import MixerSlider from './MixerSlider'



const  Mixer = () => {
  return (
    <div>
      <Navbar2 />
      <MixerSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>MIXER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Mixer is a high thrill ride where the rider is seated in buckled up seats and are ready to have themselves shaken.
        <br />
        Mixer turns your in your seats towards the sky and you are trying to keep your senses in place  but the experience us beyond any excitement you have known.
        <br />
        The mixer turns and twists you while the arm of the ride is revolving around.
        <br />
        Come and experience this high thrill recreation as you are just ready to have your life being upside down and then turn back again and then again.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default Mixer

