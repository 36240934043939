import React from 'react'
import WaterWelcome from './WaterWelcome'
import Footer from '../Footer/Footer'
import LatestOffer from '../LatestOffer/LatestOffer'
import Navbar2 from '../Navbar/Navbar2'
import WaterSlider from './WaterSlider/WaterSlider'
import WaterFamilyRides from './WaterFamilyRides'


function WaterRide() {
  return (
    <div>
      <Navbar2 />
      <WaterSlider />
      <WaterWelcome />
      <WaterFamilyRides />
      <LatestOffer />
      <Footer />
    </div>
  )
}

export default WaterRide;
