import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import GyroRideSlider from './GyroRideSlider'



const  GyroRide = () => {
  return (
    <div>
      <Navbar2 />
      <GyroRideSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>GYRO RIDE</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        If you happen to feel stuck in your upward standing position, hopelessly grounded in your rubber slippers from morning to night, and somehow dream that you could fly, spin or somersault through thin air, take a ride in the gyroscope. 
        <br />
        As you are seated in the Gyroscope, it will send you rolling, rocking, spinning, twirling head-over-tail and all over again.
        <br />
        You will feel your nerves on the edge and will be shaken with so much excitement and exhilaration.
        <br />
        Your senses will take a back seat as you are swirled and twirled in the gyro ride.
        <br />
        Hold on to your buckles tight because you are in for a whirling ride.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default GyroRide

