import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import LoopCoasterSlider from './LoopCoasterSlider'

const LoopCoaster = () => {
  return (
    <div>
      <Navbar2 />
      <LoopCoasterSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'cursive', fontSize:'44px', fontWeight:'600'}}>LOOP COASTER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Loop, loop and loop, kind of our mantra of fun!
        <br />
        Loop Coaster defining it perfectly, how to have fun.
        A thriller slide, stretched over 400 feet, with a loop at every 100, making 3 huge loops. You are accompanied along with 1 of your loved ones on a 2 seater tube to bash into this Loop Coaster.
        <br />
        As you are plunged down the slide, you could literally feel every inch of your body feel the sensation of thrill and exhilaration. You will feel your body and mind visiting different dimensions at the same time but still be together.
        <br />
        A ride where you are looped and looped just in fun and excitement.
        <br />
        Come and experience the dramatic twists and drops of this Loop Coaster, because fun cannot be defined but only had, only at Wild Waters.
        
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default LoopCoaster

