import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import WormCoasterSlider from './WormCoasterSlider'


const  WormCoaster = () => {
  return (
    <div>
      <Navbar2 />
      <WormCoasterSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>WORM COASTER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A Worm Coaster is a worm like ride that drives along some crazy tracks with loops and curves.
        <br />
        Let your loved ones join you in this ride and experience the craziness of the loops and the sharpness of the curves with some fun ascents and descents in between.
        <br />
        It is surely a one time experience that will allow your adrenaline to take charge of you as you are moving along the tracks of the ride.
        <br />
        So, buckle up your seats and hold on to this worm because you are in for a very fast wormy ride, that is exciting, thrilling and everything amazing.
        <br />
        Come along and take a seat on this Worm and let the fun begin.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default WormCoaster

