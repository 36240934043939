import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import FerrisWheelSlider from './FerrisWheelSlider'



const  FerrisWheel = () => {
  return (
    <div>
      <Navbar2 />
      <FerrisWheelSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>FERRIS WHEEL</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Ferris wheel is a mini giant wheel for your little ones. It is a vertically rotating ride attached to the cabin of the ferris wheel with small baskets hanging to accommodate your kids.
        <br />
        The kids are rotated 360° vertically as they cherish the beautiful view of the garden. They go round and round the wheel and are swinging in the little baskets.
        <br />
        They are buckled in their seats and swing around the wheel with tremendous joy and excitement.
        <br />
        This ride gives them a rush of blood and excitement as they are rotated around.
        <br />
        Let them get on the wheel and go a complete circle with complete ups and downs of the ride.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default FerrisWheel

