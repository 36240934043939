import React from 'react'
import ThrillRideOne from './ThrillRideOne'
import ThrillRideTwo from './ThrillRideTwo'
import ThrillRideThree from './ThrillRideThree'

const ThrillRide = () => {
  return (
    <div style={{backgroundImage: "url(/Images/bgImg-2.png)", backgroundSize: "cover",position: "relative", backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center'}}>
      <ThrillRideOne />
      <ThrillRideTwo />
      <ThrillRideThree />
    </div>
  )
}

export default ThrillRide
