import React from 'react'
// import './HomeCardTwo.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";




const LandRideSection = () => {


  return (

    <div className='welcome-container' style={{marginTop:'60px', marginBottom:'60px'}}>
    <div className='water-ride-container' >
      <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>LAND RIDES</h3>
      <div>
      <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px'}}>
        With 21 unique Wet rides to enjoy, get in the action, grab a tube, feel the rush and get<br /> the adrenaline pumping as you slip and slide through the rushing water. Experience the force of the waves at<br /> Paradise Beach, or race down the slides with numerous twists and turns or take a break to kick<br /> back and relax in the Crazy river amidst a tropical jungle. Come and get your thrills,<br /> spills and reinvigorating relaxation at the best Water & Amusement park in Hyderabad.</p>
      </div>
    </div>
    </div>

    

  )
}

export default LandRideSection 
