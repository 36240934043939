import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import SpiralJetSlider from './SpiralJetSlider'


const  SpiralJet = () => {
  return (
    <div>
      <Navbar2 />
      <SpiralJetSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>SPIRAL JET</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A unique thrill ride where you are hanging and swirling in air at high speed.
        <br />
        Attached to a pole are the pneumatic arms that throw you in the air, horizontally, while the seats are moving outwards, inwards and sideways rotating 360° in a synchronized momentum.
        <br />
        It is as though you are experiencing the centrifugal force of space travel. It is a ride that can be enjoyed with a group of friends or family, seated around each other in their own seats and experiencing the thrill altogether as the ride begins and swirls around.
        <br />
        What are you waiting for? Climb in one of the seats and experience what space travel feels like with the Spiral Jet at the Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default SpiralJet

