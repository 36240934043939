import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import LoopRacerSlider from './LoopRacerSlider'

const LoopRacer = () => {
  return (
    <div>
      <Navbar2 />
      <LoopRacerSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'cursive', fontSize:'44px', fontWeight:'600'}}>LOOP RACER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Loop Racer, a must-ride for every adrenaline junkie. Stretched over an impressive length of about 260 feet, standing over a dizzying 40 feet in height.
        <br />
        You can actually feel your heart race throughout the journey of this thrilling, adventurous ride. Heart pounding as you are pushed through the ride face first.
        <br />
        As you are rushed down the sleek mat, you are driven into a tube to experience the real twists and turns, downhill and uphill.
        <br />
        Feel the blood rush in your veins while you are just looping through the Loop Racer at the Wild Waters.
        <br />
        An exciting experience like none other, only promised at Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default LoopRacer

