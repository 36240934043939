import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleLeft,
	faAngleRight,
	faStar,
	faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import './Testimonial.css'

const testimonialList = [
	[
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_1.jpeg",
			name: "RK",
			rating: 4.5,
			content:
				'"Been here with my corporate friends after we had a hectic blockout period at our office. I must say, it was the best decision to choose Wild Waters, because after seeing many options we decided on this one. We had a truly refreshing and exciting day. It was calming and exciting at the same time. The rides were the best part of all. We also had some great food after all the fun. Ahh! What a fun day to remember. 5/5 Recommended."',
		},
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_3.jpeg",
			name: "Aarti",
			rating: 4.5,
			content:
				'"Wild waters truly stands up to its name, Wild and thrilling. I was here with my family. Honestly I was here because it is a water park near me but the way my expectations exceeded, fabulous. It kept my family entertained for hours and we had fun back to back, with each ride and each slide. Will visit again soon."',
		},
	],
	[
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_2.jpeg",
			name: "Obaid",
			rating: 5,
			content:
				'"Amazing…loved it…must visit place in Hyderabad. Just go for it."',
		},
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_1.jpeg",
			name: "Zara",
			rating: 4.5,
			content:
				'"My kids just went in swoosh swoosh, they loved the water rides especially the rain disco, aqua play and crazy river. They just did not want to get out of the water. They were very happy at Wild Waters."',
		},
	],
	[
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_1.jpeg",
			name: "B.Raj",
			rating: 4.5,
			content:
				'"I was at Wild Waters last year. However, I can still feel the thrill of that trip. It was such a memorable journey when I visited Hyderabad with my friends.  If I visit Hyderabad again, I will visit Wild Waters again for sure. I’m from Kolkata and I really loved the experience. It is one of my fav places in Hyderabad. Love from Kolkata."',
		},
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_3.jpeg",
			name: "Sweety Singh",
			rating: 4.5,
			content:
				'"Thank you Wild Waters for an amazing experience. Helpful staff and hygiene well maintained. It was such a perfect spot to treat my colleagues with. We booked our tickets online and went into the entrance without any hassle. A great experience overall."',
		},
	],
	[
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_1.jpeg",
			name: "A.S",
			rating: 4.5,
			content:
				'"A full day filled with excitement and fun. From the moment we arrived till we left, it is an experience to narrate. I would definitely recommend it to everyone who is looking for a day of escape from reality and wants to indulge in some mind blowing fun and relaxation. It was as good as I’m describing it."',
		},
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_3.jpeg",
			name: "B.R",
			rating: 4.5,
			content:
				'"I want to go there again. It is the best water park in Hyderabad. Very good facilities and also provide swimming clothes to swim. Very nice experience."',
		},
	],
	[
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_1.jpeg",
			name: "AK",
			rating: 4.5,
			content:
				'"Truly among the best water parks I have ever visited. I Had loads and loads of fun with my girlfriend turned fiance. I took the opportunity to propose to my girlfriend there and the staff also co-operated very well. Thank You Wild Waters for a memory for a lifetime. Everytime we visit, we will be reminded of those beautiful moments. So glad I took the step and she said Yes!"',
		},
		{
			photo:
				"https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_3.jpeg",
			name: "KD",
			rating: 4.5,
			content:
				'"Fun, fun and fun. Excitement, entertainment and just pure fun. This accurately describes what me and my friends experienced at Wild Waters."',
		},
	],
];

const Rating = ({ rating, showLabel, className, ...rest }) => (
	<p className={classNames("flex flex-wrap gap-0.5", className)} {...rest}>
		<span>
			{[...Array(5)].map((_, i) => {
				const index = i + 1;
				let content = "";
				if (index <= Math.floor(rating))
					content = (
						<FontAwesomeIcon
							icon={faStar}
							className="text-[22px] text-yellow-500"
						/>
					);
				else if (rating > i && rating < index + 1)
					content = (
						<FontAwesomeIcon
							icon={faStarHalfAlt}
							className="text-[22px] text-yellow-500"
						/>
					);
				else if (index > rating)
					content = (
						<FontAwesomeIcon
							icon={faStar}
							className="text-[22px] text-yellow-200 dark:text-opacity-20"
						/>
					);
				return <Fragment key={i}>{content}</Fragment>;
			})}
		</span>
		{showLabel && <span>{rating.toFixed(1)}</span>}
	</p>
);

Rating.propTypes = {
	rating: PropTypes.number.isRequired,
	showLabel: PropTypes.bool,
	className: PropTypes.string,
};

const TestimonialItem = ({ item }) => {
	const {  content, name } = item;
	return (
		<div className="bg-white dark:bg-slate-800 shadow-xl rounded-xl hover:-translate-y-1 h-full duration-300 p-6" >
			<div className="mt-4">
				<div className="flex justify-between items-center mb-6">
					<div className="flex items-left">
						<div className="mr-2">
							
						</div>
						<div>
							<h5 className="text-xl break-all font-medium" style={{fontFamily:"Comic Sans MS"}}>{name}</h5>
						</div>
					</div>
				</div>
				<p className="leading-[1.8] opacity-80 mb-6" style={{textAlign:"left", fontSize:"17px"}}>{content}</p>
			</div>
		</div>
	);
};
TestimonialItem.propTypes = {
	item: PropTypes.object.isRequired,
};

const Testimonial = () => {
	const [index, setIndex] = useState(0);

	const handleControl = (type) => {
		if (type === "prev") {
			setIndex(index <= 0 ? testimonialList.length - 1 : index - 1);
		} else if (type === "next") {
			setIndex(index >= testimonialList.length - 1 ? 0 : index + 1);
		}
	};
	return (
		<section className="ezy__testimonial21 light py-14 md:py-24 dark:bg-[#0b1727] text-zinc-900 dark:text-white" style={{backgroundImage:"url(/Images/bgImg5.jpeg)"}}>
			<div className="container px-4 mx-auto relative" >
				<div className="flex justify-center text-center mb-6 lg:mb-12">
					<div style={{width:"100%"}}>
						<h2 className="text-3xl leading-none md:text-[45px] font-bold mb-6" style={{color:'#082B5C',fontFamily:'Comic Sans MS'}}>
              Listen to what our Thrill-Seekers have to say
						</h2>
					</div>
				</div>

				<div className="grid grid-cols-2 gap-6 mt-12">
					{testimonialList[index].map((item, i) => (
						<div className="col-span-2 md:col-span-1" key={i} style={{color:"#28303B", fontFamily:'Montserrat'}}>
							<TestimonialItem item={item}  />
						</div>
					))}
				</div>

				<div className="relative flex justify-center items-center my-12">
					<button
						className="text-lg bg-white shadow-2xl dark:bg-slate-800 opacity-75 hover:opacity-100 w-12 h-12 flex justify-center items-center rounded-full mr-4"
						onClick={() => handleControl("prev")}
					>
						<FontAwesomeIcon icon={faAngleLeft}  style={{color:"#28303B"}} />
					</button>
					<button
						className="text-lg bg-white shadow-2xl dark:bg-slate-800 opacity-75 hover:opacity-100 w-12 h-12 flex justify-center items-center rounded-full"
						onClick={() => handleControl("next")}
					>
						<FontAwesomeIcon icon={faAngleRight}  style={{color:"#28303B"}} />
					</button>
				</div>
			</div>
		</section>
	);
};

export default Testimonial;