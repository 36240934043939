import React from 'react';
import './FamilyRide.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function FamilyRideFour() {

	return (
		<div style={{marginTop:'20px'}}>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div className="container" style={{width:'80%', paddingBottom:"30px"}}>
                <div className="row position-relative mt-n1-9">
                    
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/voyage-1.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Voyage</h3>
                                {/* <span className="font-weight-600 text-secondary">Photographer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/voyage" className="text-white">About Voyage</Link></h3>
                                        <p className="text-white mb-0">A fun journey with twisty loops and spinning turns, that will leave you breathless. So, buckle up and let’s begin the Voyage.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/voyage" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C", padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/rapids1.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info" >
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Rapids</h3>
                                {/* <span className="font-weight-600 text-secondary">Psychologist</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link href="/rapids" className="text-white">About Rapids</Link></h3>
                                        <p className="text-white mb-0">Experience the real rush with Rapids. Give in yourself to the excitement and exhilaration, as you go through all the twists along the ride.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                        <Link to="/rapids" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C", padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
export default FamilyRideFour;

