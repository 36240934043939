// import React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
// import MenuItem from '@mui/material/MenuItem';
// import Offer from '../Offer/Offer';
// import Nav from 'react-bootstrap/Nav';



// const pages = ['WATER RIDES', 'LAND RIDES', 'PARK INFO','OFFERS','RESORT', 'CONTACT US'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// function Navbar() {
//   const [anchorElNav, setAnchorElNav] = React.useState(null);
//   const [anchorElUser, setAnchorElUser] = React.useState(null);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };
//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   return (
//     <AppBar position="static" style={{fontFamily:'Montserrat'}}>
//       <Offer />
//       <Container maxWidth="xxl" style={{backgroundColor:'#FFFFFF', color:'#BDBDBD'}}>
//         <Toolbar disableGutters>
//           <Typography
//             variant="h6"
//             noWrap
//             component="a"
//             href="/"
//             sx={{
//               // mr: 2,
//               display: { xs: 'none', md: 'flex' },
//               fontFamily: 'Jost, sans-serif',
//               // fontWeight: 400,
//               // letterSpacing: '.3rem',
//               // color: 'inherit',
//               // textDecoration: 'none',
//             }}
//           >
//             <img src='/Images/logo-new.png' alt='logo' style={{width:'110px',padding:'10px'}}/>
//           </Typography>

//           <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="inherit"
//               style={{color:'#212121'}}
//             >
//               <MenuIcon />
//             </IconButton>
//             <Menu
//               style={{color:'#212121'}}
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'left',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: 'block', md: 'none' },
//               }}
//             >
//               {pages.map((page) => (
//                 <MenuItem  key={page} onClick={handleCloseNavMenu}>
//                   <Typography textAlign="center">{page}</Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>

//           <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent:'center' } }}>
//             {/* {pages.map((page) => (
//               <Button
//                 key={page}
//                 onClick={handleCloseNavMenu}
//                 sx={{ my: 2, display: 'block', color:'#212121', fontWeight:'600', fontFamily:'Montserrat' }}
//               >
//                 {page}
//               </Button>
//             ))} */}
//             {/* ++++++++++++++ new navbar add +++++++++++++++++++++++++++++++ */}
//             <div style={{display:"flex", justifyContent:"center"}}>
//             <Nav className="me-auto" sx={{flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent:'center', alignItems:'center', fontFamily:'Montserrat'} }}>
            
//             <Nav.Link to="/waterride" style={{fontFamily:'Montserrat', color:'#212121', fontWeight:"600"}}>WATER RIDES</Nav.Link>
//             <Nav.Link to="/landride" style={{fontFamily:'Montserrat', color:'#212121', fontWeight:"600"}}>LAND RIDES</Nav.Link>
//             <Nav.Link to="/parkinfo" style={{fontFamily:'Montserrat', color:'#212121', fontWeight:"600"}}>PARK INFO</Nav.Link>
//             <Nav.Link href="#offer" style={{fontFamily:'Montserrat', color:'#212121', fontWeight:"600"}}>OFFERS</Nav.Link>
//             <Nav.Link href="#resort" style={{fontFamily:'Montserrat', color:'#212121', fontWeight:"600"}}>RESORT</Nav.Link>
//             <Nav.Link href="#contact" style={{fontFamily:'Montserrat', color:'#212121', fontWeight:"600"}}>CONTACT US</Nav.Link>
            
//             </Nav>
//             </div>
//           </Box>

//           <Box sx={{ flexGrow: 0 }}>
//             {/* <Tooltip title="Open settings">
//               <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
//               </IconButton>
//             </Tooltip> */}

//             <Button style={{backgroundColor:'#0A2254', color:"#F0F8FF", padding:'10px 20px 10px 20px', borderRadius:'18px', fontSize:'13px', fontFamily:'Montserrat'}}>
//               BOOK NOW
//             </Button>
//             <Menu
//               sx={{ mt: '45px' }}
//               id="menu-appbar"
//               anchorEl={anchorElUser}
//               anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               open={Boolean(anchorElUser)}
//               onClose={handleCloseUserMenu}
//             >
//               {settings.map((setting) => (
//                 <MenuItem key={setting} onClick={handleCloseUserMenu}>
//                   <Typography textAlign="center">{setting}</Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// }
// export default Navbar;


// ++++++++++++++++++++++++++++++ 2-4-2024 +++++++++++++++++++++++++
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Offer from '../Offer/Offer';
import Nav from 'react-bootstrap/Nav';

const pages = ['WATER RIDES', 'LAND RIDES', 'PARK INFO', 'OFFERS', 'RESORT', 'CONTACT US'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" style={{ fontFamily: 'Montserrat' }}>
      <Offer />
      <Container maxWidth="xxl" style={{ backgroundColor: '#FFFFFF', color: '#BDBDBD' }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Jost, sans-serif',
            }}
          >
            <img src='/Images/logo-new.png' alt='logo' style={{ width: '110px', padding: '10px' }} />
          </Typography>

          <Box sx={{ flexGrow: 1 }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 } }}>
            <Nav className="me-auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {pages.map((page, index) => (
                <Nav.Link key={index} to={`/${page.toLowerCase().replace(/\s+/g, '')}`} style={{ fontFamily: 'Montserrat', color: '#212121', fontWeight: "600" }}>{page}</Nav.Link>
              ))}
            </Nav>
          </Box>

          <Box>
            <Button variant="contained" color="primary" style={{ backgroundColor: '#0A2254', color: "#F0F8FF", padding: '10px 20px', borderRadius: '18px', fontSize: '13px', fontFamily: 'Montserrat' }}>
              BOOK NOW
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
