import React from 'react'

const ContactMap = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15228.306667475803!2d78.11403800000001!3d17.408108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbef55b10e2f25%3A0x166519cddad96d54!2sWild%20Waters%20-%20Water%20%26%20Amusement%20Theme%20Park%20-%20Hyderabad!5e0!3m2!1sen!2sin!4v1712062482896!5m2!1sen!2sin"
        width="100%"
        height="600"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default ContactMap
