import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import CoffeeCupsSlider from './CoffeeCupsSlider'


const CoffeeCups = () => {
  return (
    <div>
      <Navbar2 />
      <CoffeeCupsSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>COFFEE CUPS</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Step into a giant cup of coffee, delicately ornamented, sitting around a colossal teapot. Prepare for a triple spin sensation.
        <br />
        As the ride begins, the rotation begins, the cups are rotating around a decanter that is already pivoting beneath the cups.
        <br />
        The fun doesn’t stop here, you are in for more. Now, as you take hold of the wheel in front of you and twist it, you will see that the rotation goes faster.
        <br />
        The faster you rotate the wheel, the faster the rotations of the cups are. It’s like a rotation in a rotating cup over a rotating base altogether.
        <br />
        Feel spinny right!! It’s a spinning delight with a triple spinning sensation.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default CoffeeCups

