import React from 'react'
import LandRideFamily from './LandRideFamily'
import LandRideFamilyTwo from './LandRideFamilyTwo'
import LandRideFamilyThree from './LandRideFamilyThree'
import LandRideFamilyFour from './LandRideFamilyFour'

const LandRideFamilySection = () => {
  return (
    <div style={{backgroundImage: "url(/Images/bgImg5.jpeg)", backgroundSize: "contain",position: "relative"}}>
      <LandRideFamily />
      <LandRideFamilyTwo />
      <LandRideFamilyThree />
      <LandRideFamilyFour />
    </div>
  )
}

export default LandRideFamilySection
