import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import AquaPlaySlider from '../WaterRide/AquaPlaySlider/AquaPlaySlider'

const AquaPlay = () => {
  return (
    <div>
      <Navbar2 />
      <AquaPlaySlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>AQUA PLAY</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Let your kid’s imagination run wild and explore the splash-worthy activities. 
        <br />
        Aqua Play being the ultimate source of fun and excitement for your kid, featuring 5 interactive fountains and 3 exciting slides to zoom down into the child friendly pool.
        <br />
        Keeping your little ones entertained with hours of exploring, laughter and joy.
        <br />
        Let them splash with their new found friends into a safe yet thrilling experience of cascading waterfalls and playful sprays.
        <br />
        Let them take the charge into the Aqua Play at Wild Waters and enjoy the view of your little ones joy and excitement.
        <br />
        A safe and exciting swooshing experience for your kids that they can treasure forever in their memories.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default AquaPlay

