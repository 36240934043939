import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import SwingerSlider from './SwingerSlider'


const  Swinger = () => {
  return (
    <div>
      <Navbar2 />
      <SwingerSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>SWINGER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A swinging experience like none other. A thrilling ride for you and your homies. A Mushroom shaped canopy has chairs attached to it that are thrown in air as you go to swing.
        <br />
        This Swinger takes you on a whirlwind. As you are seated in the swings, it releases into air above 8 meters in height.
        <br />
        As you swing you go up and down while you are swaying in the air.
        <br />
        Get your group onto this and feel the wonders of this swing as you are just sitting in open air and feeling the sensation of being a free bird in the sky.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default Swinger

