


// +++++++++++++++ new code +++++++++++++++++++++

import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import './HomeCardOne.css'; // Import your CSS file for styling

const RevealingSection = () => {
  const [inViewRef, inView] = useInView({
    triggerOnce: false, // Trigger multiple times while scrolling
    rootMargin: '-50px 0px',
  });
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true); // Set animate to true when the section is in view
    } else {
      setAnimate(false); // Reset animate when the section goes out of view
    }
  }, [inView]); // Add inView as a dependency for the useEffect hook

  return (
    <div ref={inViewRef} className={`revealing-section ${animate ? 'animate' : ''}`} style={{backgroundImage: "url(/Images/bgImg2.jpeg)", backgroundSize: "contain",position: "relative"}}>
      
      <div className="column">
        {/* Image */}
        <img src="/Images/kids.jpeg" alt="kidsImg" />
      </div>
      <div className="column">
        {/* Content */}
        <h2 style={{color:'#082B5C',fontFamily:'Comic Sans MS', textAlign:'center', fontSize:'44px', fontWeight:'600', padding:'10px'}}>KIDS RIDES</h2>
        <hr className='hr-line' style={{ width: '40px', margin: '0 auto', display: "flex", textAlign:"center", padding:"3px", borderWidth: "4px", borderColor:"#FED001"}} />
        <p style={{fontSize:'18px'}}>With 21 unique Wet rides to enjoy, get in the action, grab a tube, feel the rush and get the adrenaline pumping as you slip and slide through the rushing water. With 21 unique Wet rides to enjoy, get in the action, grab a tube, feel the rush and get the adrenaline pumping as you slip and slide through the rushing water.</p>
        <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                  <Link to="www.google.com" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#082B5C', borderRadius:'20px', fontWeight:'500', fontFamily:'Comic Sans MS', padding:'10px', color:'#fff'}}>EXPLORE</Link>
        </div>
      </div>
      
    </div>
  );
}

export default RevealingSection;
