import React from 'react'
import NewWelcomeSection from '../HomeSection/NewWelcomeSection'
import HomeCardOne from '../HomeCards/HomeCardOne'
import HomeCardTwo from '../HomeCards/HomeCardTwo'
import HomeCardThree from '../HomeCards/HomeCardThree'
import LatestOffer from '../LatestOffer/LatestOffer'
import Footer from '../Footer/Footer'
import HomeCardFour from '../HomeCards/HomeCardFour'
import FAQ from '../FAQ/FAQ'
import Testimonial from '../Testimonial/Testimonial'
import Navbar2 from '../Navbar/Navbar2'
import VideoBanner from '../VideoBanner/VideoBanner'




function HomePage() {
  return (
    <div>
      <Navbar2 />
      <VideoBanner />
      <NewWelcomeSection />
      <HomeCardOne />
      <HomeCardTwo />
      <HomeCardThree />
      <HomeCardFour />
      <Testimonial />
      <FAQ />
      <LatestOffer />
      <Footer />
    </div>
  )
}

export default HomePage
