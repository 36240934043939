import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import PiratesCoveSlider from '../WaterRide/PiratesCoveSlider/PiratesCoveSlider'

const PiratesCove = () => {
  return (
    <div>
      <Navbar2 />
      <PiratesCoveSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>PIRATES COVE</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Pirates coves, a swashbuckling adventure for an ultimate family entertainment waterzone at Wild Waters. A thrilling and exciting lineup of 6 adult slides and 6 slides for kids, ranging from height limits of 6 feets to 21 feets.
        <br />
        A thrilling adventure for all age groups together. 
        <br />
        Tilting cones and refreshing showers, a child friendly pool to cool off in.
        <br />
        A setup of twisting slides of different lengths and designs to take a plunge in to explore an all new definition of fun
        <br />
        Oh Wait! There is more to uncover… the real treasure of this pirate cove are the 2 giant buckets that tip over every 4-5 minutes, drenching everyone who stands below in waves of chuckles and rejoiced entertainment.
        <br />
        Ready, Set, Sail for this Pirates cover to unveil the treasure of unforgettable memories with your crew at Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default PiratesCove

