import React from 'react'
import KidsRideOne from './KidsRideOne'
import KidsRideTwo from './KidsRideTwo'
import KidsRideThree from './KidsRideThree'

const KidsRide = () => {
  return (
    <div style={{backgroundImage: "url(/Images/bgImg-4.jpeg)", backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center'}}>
      <KidsRideOne />
      <KidsRideTwo />
      <KidsRideThree />
    </div>
  )
}

export default KidsRide
