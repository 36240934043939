import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import WaveRiderSlider from '../WaterRide/WaveRiderSlider/WaveRiderSlider'

const WaveRider = () => {
  return (
    <div>
      <Navbar2 />
      <WaveRiderSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>WAVE RIDER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A thrill like never before can be experienced only at the Wave Rider of Wild Waters
        <br />
        To go at a speed of 40 km per hour without being on any vehicle, Whoosh!
        <br />
        Starting at a towering height of 40 feet and you are pushed down a 240 foot long track to make sure you explore a heart pounding journey.
        <br />
        You will feel your adrenaline giving your heart a pump of energy as you are pushed straight down the slide, impersonating the ups and downs of a wave.
        <br />
        If you are a thrill seeker, this was made for you! 
        <br />
        Hold on tight as you go through this ride that defies gravity and is filled with twists and turns along the way.
        <br />
        It’s a chance to catch a wave and experience a rush of blood on the Wave Rider at Wild Waters.
        Experience the thrill of the Wave Rider at Wild Waters!
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default WaveRider

