import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import CrazyRiverSlider from '../WaterRide/CrazyRiverSlider/CrazyRiverSlider'

const CrazyRiver = () => {
  return (
    <div>
      <Navbar2 />
      <CrazyRiverSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>CRAZY RIVER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Is a lazy river exciting? Nahh, Right!!
        We are more like a Crazy River people, that pushes you around, rolls you over with unexpected twists and turns around every corner - a frenzy of excitement.
        <br />
        So come on! Let’s hop on a tube and float along the 6 foot long crazy river that takes you on a thrilling journey amidst the tropical jungle landscape. 
        <br />
        Let’s feel the adrenaline kick in! Let’s feel the rush of excitement, let every moment be filled with surprises.
        <br />
        It’ll leave you breathless but you will keep craving for more.
        <br />
        Come and experience the wild side of the Wild Waters with the Crazy River.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default CrazyRiver

