import React from 'react';
import './FamilyRide.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function FamilyRideThree() {
	return (
		<div style={{marginTop:'20px'}}>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div className="container" style={{width:'80%'}}>
                
                <div className="row position-relative mt-n1-9">
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/free-fal.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className="mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Free Fall</h3>
                                {/* <span className="font-weight-600 text-secondary">Web Designer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link href="/freefall" className="text-white">About Free Fall</Link></h3>
                                        <p className="text-white mb-0">The feeling of weightlessness as you free fall at a speed of 50 km per hour on this ride. Let’s just Free Fall.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                        <Link to="/freefall" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/matt-racer.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px",objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Matt Racer</h3>
                                {/* <span className="font-weight-600 text-secondary">Photographer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link href="/mattracer" className="text-white">About Matt Racer</Link></h3>
                                        <p className="text-white mb-0">An exciting race challenge to have with your friends, who will reach the end of the ride first, as you all are plunged into the ride head down.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                            
                                        <Link to="/mattracer" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/tornado2.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px",objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info" >
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Tornado</h3>
                                {/* <span className="font-weight-600 text-secondary">Psychologist</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link href="/tornado" className="text-white">About Tornado</Link></h3>
                                        <p className="text-white mb-0">It is exactly how it sounds, a chaos of excitement, a turmoil of fun, a thrill with all the twists and turns of a tornado.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                        <Link to="/tornado" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
export default FamilyRideThree;

