import React from 'react'
import HeroSection from '../HeroSection/HeroSection'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import RainDiscoSlider from '../WaterRide/RainDiscoSlider/RainDiscoSlider'

const RainDisco = () => {
  return (
    <div>
      <Navbar2 />
      {/* <HeroSection /> */}
      <RainDiscoSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>RAIN DISCO</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Disco Deewane!! Yes we are…
        Rain Disco is here to bring out the Disco Deewane in you. Spread over a stunning 3000 square feet, providing you with an ultimate experience of rhythm and rain, only at Rain Disco.
        <br />
        Groove to your crazy moves as the pulsating beat matches the rain falls in perfect synchronization. 
        <br />
        A promised unforgettable experience of rainfall, rainbows, music, rhythm and grooves with your friends and family.
        <br />
        Be a part of the electrifying atmosphere and make some electrifying memories with friends and family.
        <br />
        Come on the dance floor because there is nothing like Pappu Can’t Dance Saala! 
        At the Rain Disco of Wild waters even Pappu can Dance.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default RainDisco
