import React from 'react';
// import './FamilyRide.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function LandRideFamilyTwo() {
	return (
		<div>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div className="container" style={{width:'80%'}}>
                <div className="row position-relative mt-n1-9">
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/ocean-train-1.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className="mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Ocean Train</h3>
                                {/* <span className="font-weight-600 text-secondary">Web Designer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/oceantrain" className="text-white">About Ocean Train</Link></h3>
                                        <p className="text-white mb-0">An oceanic, miniature trackless train, that takes you around the park at a comfortable speed to have a beautiful drive around the park</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                        <Link to="/oceantrain" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/kangaroo-1.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Kangaroo Jump</h3>
                                {/* <span className="font-weight-600 text-secondary">Photographer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/kangaroojump" className="text-white">About Kangaroo Jump</Link></h3>
                                        <p className="text-white mb-0">A vivid and exciting ride that offers an opportunity to feel the sensation of being in a kangaroo’s belly, while hopping and jumping along.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                        <Link to="/kangaroojump" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/worm-coaster.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info" >
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Worm Coaster</h3>
                                {/* <span className="font-weight-600 text-secondary">Psychologist</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/wormcoaster" className="text-white">About Worm Coaster</Link></h3>
                                        <p className="text-white mb-0">A worm like drive that moves along some crazy twists, loops with all the curvy turns, making it the ultimate family ride to hop on.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                        <Link to="/wormcoaster" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
export default LandRideFamilyTwo;
