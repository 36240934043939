import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import KangarooJumpSlider from './KangarooJumpSlider'


const  KangarooJump = () => {
  return (
    <div>
      <Navbar2 />
      <KangarooJumpSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>KANGAROO JUMP</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A bouncy ride in the belly of kangaroos impersonating real kangaroo-like jumps.
        <br />
        An ideal family fun ride that brings the essence of the Australian Kangaroos to your adventure. With the ride design resembling the kangaroo’s motion, this ride is a fun adventure like no other.
        <br />
        Climb into the kangaroo bellies that are carved to mimic the nestling of a real kangaroo. Let yourself be hopped as the ride comes into action.
        <br />
        Each jump provides a surreal sensation, giving you tons of exciting memories to take back home.
        <br />
        Let the spirit of these Australian animals give you a bouncing experience that will leave you and your loved ones with a burst of laughter and joy.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default KangarooJump

