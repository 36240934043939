import React from 'react'
import LandRideSection from './LandRideSection'
import LandRideFamily from './LandRideFamily'
import LandRideFamilyTwo from './LandRideFamilyTwo'
import LandRideFamilyThree from './LandRideFamilyThree'
import LandRideFamilyFour from './LandRideFamilyFour'
// import WaterGallery from '../WaterRide/WaterGallery'
import LatestOffer from '../LatestOffer/LatestOffer'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import LandBanner from './LandBanner/LandBanner'
import LandRideFamilySection from './LandRideFamilySection'

const LandRide = () => {
  return (
    <div>
      <Navbar2 />
      <LandBanner />
      <LandRideSection />
      <LandRideFamilySection />
      {/* <WaterGallery /> */}
      <LatestOffer />
      <Footer />
    </div>
  )
}

export default LandRide
