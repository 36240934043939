import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import AeroFighterSlider from './AeroFighterSlider'


const AeroFighter = () => {
  return (
    <div>
      <Navbar2 />
      <AeroFighterSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>AERO FIGHTER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Aero Fighter is an adventurous yet action filled ride offers a thrill packed experience to people of all age groups.
        <br />
        Take a seat on the airplane shaped cabin, securely attached through pneumatic controlled arms to the main rocket.
        <br />
        When the ride begins, the arms are gradually lifted with high speed rotations sending you through soaring air.
        <br />
        Each cabin has a controller that allows the rider to control the ride, as you push the buttons of the controller, you can maneuver your cabin up or down as per your liking, giving you an ultimate experience of flying an airplane.
        <br />
        So take control of your plane, buckle up your seat belts as you prepare to go on this aerial adventure.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default AeroFighter

