import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import OceantrainSlider from './OceantrainSlider'


const  OceanTrain = () => {
  return (
    <div>
      <Navbar2 />
      <OceantrainSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>OCEAN TRAIN</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Embark on an oceanic drive with the Ocean Train. An ocean themed, trackless train that boasts 5 charming compartments, seamlessly operated electrically.
        <br />
        This cute miniature train takes you around the Wild Waters Adventure and Water park, providing a wonderful perspective of the park’s mesmerizing sights and vibrant surroundings.
        <br />
        Ride along at a comfortable pace, with stylish seatings to ride around the park in style and comfort.
        <br />
        A ride for all age groups, for your family, for your friends group to take in a panoramic view of the diverse attractions at the park.
        <br />
        Come aboard as the train leaves its platform to take an oceanic journey around the Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default OceanTrain

