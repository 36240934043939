

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Col, Row } from "react-bootstrap";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import './FAQ.css'

export default function AccordionExpandIcon() {
  return (
		<div style={{backgroundImage: "url(/Images/bgImg2.jpeg)", backgroundSize: "contain",position: "relative"}}>
			<Row className="justify-content-center mb-md-4" >
				<Col lg={12} xl={12} className="text-center">
					<h2 className="ezy__faq3-heading mb-0" style={{color:'#082B5C',fontFamily:'Comic Sans MS', fontSize:"40px", fontWeight:"600", marginTop:"30px"}}>
 							{/* Frequently Asked Questions */}
							Get Answers to your Concerns
					</h2>
				</Col>
			</Row>
    <section style={{ padding: '40px' }}> {/* Added more padding to the section */}
      <Grid container spacing={2} > {/* Using Grid container for two-column layout */}
			{/* ++++++++++ 1st faq ++++++++++++ */}
        <Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>Is Wild Waters open? What are the timings of Wild Waters?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							"Wild waters is open throughout the week. The timings are as follows:
							Monday to Friday: 11:00 am - 06:00 pm
							Weekends/Public Holidays: 11:00 am - 07:00 pm"
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 2nd faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>What is the payment method I should use while paying and can I book my tickets online?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							"All standard payment methods are accepted including cards, cash, UPI, wallets like Phonepe, GooglePay, PayTM, Amazon Pay and etc Of Course you can book your tickets online from anywhere, just visit <Link to="https://bookings.wildwaters.in/">https://bookings.wildwaters.in/</Link>"
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 3rd faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>What are Wild Waters ticket prices?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							"Kids less than 90 cm get a free entry.A child ticket is applicable to kids between 90-140 cms An adult ticket is applicable to anyone above 140 cms."
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 4th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>Is there any discount for me?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							You can check out the latest offers and discounts here.<Link>https://wildwaters.in/offers.php</Link> 
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 5th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>Where is Wild Waters located?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							Wild Waters is exactly at:
							Sy.No.125, MASANIGUDA VILLAGE, 
							SHANKARPALLI, RANGA REDDY DIST. HYDERABAD – 501203
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 6th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>What do I get in the park ticket?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							The park ticket includes entry to the park, all land rides, water rides and facilities.
							Although, services like food, drinks, lockers and swimming costumes are needed to be paid for if required.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 7th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>What are the food arrangements? Can I get my own food in Wild Waters?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							You can explore our local and international cuisines at the food court. We have got your hunger covered with lip smacking edibles. We have got Food Express, Island Cafe, Adventure Cafe, to let your taste buds take a ride of their own.
							Unfortunately, you cannot get your own food inside as outside food is not allowed into the park. However, we have got you covered with multiple options from a healthy bite to a guilty pleasure snack that you can relish on.
							We have got all kinds of food, ranging from vegetarian choices to non-veg platters. Local to international cuisines. Snacks, beverages, refreshments, ice creams, softies, sweets and savories.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 8th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>Does the park have a dress code ? If Yes, what is it?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							Yes, In order to maintain hygiene and safety at the park, we do have a dress code at the park.
							Only nylon and polyester costumes are allowed inside the park.
							Do not wear scarves or shawls while enjoying a ride to ensure your safety and the safety of other visitors.
							Avoid loose ornaments and spectacles. 
							Footwear is not allowed in water rides. Loose footwear and dress not allowed in Adventure and Amusement rides.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 9th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>What about water or beverages?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							We have got water filters and water plants planted all around the park that contain filtered water, maintained as per ISO standards and absolutely safe to drink.
							In case you are skeptical about drinking from the water plants, packaged drinking bottles and beverages are always available at the food courts.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 10th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>Can I get my own swimming costume?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							Of Course you can, but please make sure it fits the dress code. 
							Even if it doesn't, we still have the swimming costumes available for purchase at the park that will allow no hindrance in your fun.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 11th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>Is Wild Waters safe for women and kids?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							Safety can be the least of your concerns at Wild Waters 
							as we have strict regulations and safety personnel staffed sufficiently.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 12th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>What can I do for my own safety?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							Sure, here are some tips:
							Please follow the regulations set by the park.
							Wear comfortable clothing and footwear.
							Hydration and UV Protection (sunscreen) is a must.
							Please follow safety instructions near the rides, listen to lifeguards and ride operators
							Please maintain distance and form a queue. Do not try to jump the cue.
							If you have a kid along, make sure to put contact details in his/her pocket.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 13th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>How can I keep my personal belongings safe?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							We have a locker facility available at the park for you to safely store your personal belongings.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 14th faq +++++++ */}
				<Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon /> }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>Can I use my ticket for another day?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							Unfortunately no, it is a one time use ticket, available to use only on the date of purchase.
							You cannot use it for any other day even if you haven’t used it on the day of purchase.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
				{/* ++++++++ 15th faq +++++++ */}
        <Grid item xs={12} md={6}>
          <Accordion style={{borderRadius:"20px"}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              sx={{ backgroundColor: '#f5f5f5', borderRadius:"20px" }} // Set background color
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily:"Comic Sans MS" }}>Do I pay for the rides separately at Wild Waters?</Typography> {/* Added bold font weight and changed text color */}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '20px' }}> {/* Set background color and added padding */}
              <Typography variant="body1" sx={{ color: '#555', fontSize:"17px" }}> {/* Changed text color */}
							No, you do not have to pay for the rides separately as they are inclusive in your ticket.
							You get unlimited rides access for the day of the ticket purchased.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </section>
		</div>
  );
}



