import React from 'react'
import Navbar2 from '../Navbar/Navbar2'
import StudentOffer from './StudentOffer'
import Footer from '../Footer/Footer'
import WaterSlider from '../WaterRide/WaterSlider/WaterSlider'
import LatestOffer from '../LatestOffer/LatestOffer'

const Offers = () => {
  return (
    <div>
      <Navbar2 />
      {/* <HeroSection /> */}
      <WaterSlider />
      <StudentOffer />
      <LatestOffer />
      <Footer />
    </div>
  )
}

export default Offers
