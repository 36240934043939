import './App.css';
import {Routes, Route } from 'react-router-dom';
import WaterRide from './Components/WaterRide/WaterRide';
import HomePage from './Components/HomePage/HomePage';
import LandRide from './Components/LandRide/LandRide';
import ParadiseBeach from './Components/ParadiseBeach/ParadiseBeach';
import Contact from './Components/ContactPage/Contact';
import RainDisco from './Components/RainDisco/RainDisco';
import PiratesCove from './Components/PiratesCove/PiratesCove';
import AquaPlay from './Components/AquaPlay/AquaPlay';
import CrazyRiver from './Components/CrazyRiver/CrazyRiver';
import WaveRider from './Components/WaveRider/WaveRider';
import FreeFall from './Components/FreeFall/FreeFall';
import MattRacer from './Components/MattRacer/MattRacer';
import Tornado from './Components/Tornado/Tornado';
import Rapids from './Components/Rapids/Rapids';
import LoopRacer from './Components/LoopRacer/LoopRacer';
import Twister from './Components/Twister/Twister';
import Cyclone from './Components/Cyclone/Cyclone';
import Backlash from './Components/Backlash/Backlash';
import LoopCoaster from './Components/LoopCoaster/LoopCoaster';
import Hurricane from './Components/Hurricane/Hurricane';
import Tunnel from './Components/Tunnel/Tunnel';
import Harakiri from './Components/Harakiri/Harakiri';
import Boomerang from './Components/Boomerang/Boomerang';
import Carousel from './Components/LandRide/Carousel/Carousel';
import AeroFighter from './Components/LandRide/AeroFighter/AeroFighter';
import CoffeeCups from './Components/LandRide/CoffeeCups/CoffeeCups';
import OceanTrain from './Components/LandRide/OceanTrain/OceanTrain';
import KangarooJump from './Components/LandRide/KangarooJump/KangarooJump';
import WormCoaster from './Components/LandRide/WormCoaster/WormCoaster';
import PirateShip from './Components/LandRide/PirateShip/PirateShip';
import Octopus from './Components/LandRide/Octopus/Octopus';
import Swinger from './Components/LandRide/Swinger/Swinger';
import WaveSurfer from './Components/LandRide/WaveSurfer/WaveSurfer';
import SpiralJet from './Components/LandRide/SpiralJet/SpiralJet';
import TagadaDisco from './Components/LandRide/TagadaDisco/TagadaDisco';
import JumboTrain from './Components/LandRide/KidsRide/JumboTrain/JumboTrain';
import ApacheRide from './Components/LandRide/KidsRide/ApacheRide/ApacheRide';
import MiniPirateShip from './Components/LandRide/KidsRide/MiniPirateShip/MiniPirateShip';
import MiniDisco from './Components/LandRide/KidsRide/MiniDisco/MiniDisco';
import OceanWalk from './Components/LandRide/KidsRide/OceanWalk/OceanWalk';
import FerrisWheel from './Components/LandRide/KidsRide/FerrisWheel/FerrisWheel';
import FlyingCar from './Components/LandRide/KidsRide/FlyingCar/FlyingCar';
import FrogHopper from './Components/LandRide/KidsRide/FrogHopper/FrogHopper';
import Pendulum from './Components/LandRide/ThrillRide/Pendulum/Pendulum';
import ZipLine from './Components/LandRide/ThrillRide/ZipLine/ZipLine';
import GyroRide from './Components/LandRide/ThrillRide/GyroRide/GyroRide';
import Mixer from './Components/LandRide/ThrillRide/Mixer/Mixer';
import Tsunami from './Components/LandRide/ThrillRide/Tsunami/Tsunami';
import Thrill360 from './Components/LandRide/ThrillRide/360/Thrill360';
import RotaryBees from './Components/LandRide/KidsRide/RotaryBees/RotaryBees';
import SkyDrop from './Components/LandRide/ThrillRide/SkyDrop/SkyDrop';
import ParkInfo from './Components/ParkInfo/ParkInfo';
import Offers from './Components/OffersPage/Offers';
import Voyage from './Components/Voyage/Voyage';
import Thrill from './Components/Thrill/Thrill';
import Kids from './Components/Kids/Kids';



function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<HomePage />}></Route>
        <Route path='/waterride' element={<WaterRide />}></Route>y
        <Route path='/landride' element={<LandRide />}></Route>
        <Route path='/parkinfo' element={<ParkInfo />}></Route>
        <Route path='/paradisebeach' element={<ParadiseBeach />}></Route>
        <Route path='/raindisco' element={<RainDisco />}></Route>
        <Route path='/piratescove' element={<PiratesCove />}></Route>
        <Route path='/aquaplay' element={<AquaPlay />}></Route>
        <Route path='/crazyriver' element={<CrazyRiver />}></Route>
        <Route path='/waverider' element={<WaveRider />}></Route>
        <Route path='/freefall' element={<FreeFall />}></Route>
        <Route path='/mattracer' element={<MattRacer />}></Route>
        <Route path='/tornado' element={<Tornado />}></Route>
        <Route path='/rapids' element={<Rapids />}></Route>
        <Route path='/loopracer' element={<LoopRacer />}></Route>
        <Route path='/twister' element={<Twister />}></Route>
        <Route path='/hurricane' element={<Hurricane />}></Route>
        <Route path='/cyclone' element={<Cyclone />}></Route>
        <Route path='/backlash' element={<Backlash />}></Route>
        <Route path='/loopcoaster' element={<LoopCoaster />}></Route>
        <Route path='/tunnel' element={<Tunnel />}></Route>
        <Route path='/harakiri' element={<Harakiri />}></Route>
        <Route path='/boomerang' element={<Boomerang />}></Route>
        <Route path='/carousel' element={<Carousel />}></Route>
        <Route path='/aerofighter' element={<AeroFighter />}></Route>
        <Route path='/coffeecups' element={<CoffeeCups />}></Route>
        <Route path='/oceantrain' element={<OceanTrain />}></Route>
        <Route path='/kangaroojump' element={<KangarooJump />}></Route>
        <Route path='/wormcoaster' element={<WormCoaster />}></Route>
        <Route path='/pirateship' element={<PirateShip />}></Route>
        <Route path='/octopus' element={<Octopus />}></Route>
        <Route path='/swinger' element={<Swinger />}></Route>
        <Route path='/wavesurfer' element={<WaveSurfer />}></Route>
        <Route path='/spiraljet' element={<SpiralJet />}></Route>
        <Route path='/tagadadisco' element={<TagadaDisco />}></Route>
        <Route path='/jumbotrain' element={<JumboTrain />}></Route>
        <Route path='/apacheride' element={<ApacheRide />}></Route>
        <Route path='/minipirateship' element={<MiniPirateShip />}></Route>
        <Route path='/minidisco' element={<MiniDisco />}></Route>
        <Route path='/oceanwalk' element={<OceanWalk />}></Route>
        <Route path='/ferriswheel' element={<FerrisWheel />}></Route>
        <Route path='/flyingcar' element={<FlyingCar />}></Route>
        <Route path='/froghopper' element={<FrogHopper />}></Route>
        <Route path='/pendulum' element={<Pendulum />}></Route>
        <Route path='/zipline' element={<ZipLine />}></Route>
        <Route path='/gyroride' element={<GyroRide />}></Route>
        <Route path='/mixer' element={<Mixer />}></Route>
        <Route path='/tsunami' element={<Tsunami />}></Route>
        <Route path='/360' element={<Thrill360 />}></Route>
        <Route path='/rotarybees' element={<RotaryBees />}></Route>
        <Route path='/skydrop' element={<SkyDrop />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/offers' element={<Offers />}></Route>
        <Route path='/voyage' element={<Voyage />}></Route>
        <Route path='/thrill' element={<Thrill />}></Route>
        <Route path='/kids' element={<Kids />}></Route>




      </Routes>
    {/* <HomePage /> */}
{/* +++++++++++++++++++++++++++++++++++ Water Rides Start ++++++++++++++++++++++++++ */}
      {/* <WaterRide /> */}
      {/* <LandRide /> */}
      {/* <ParadiseBeach /> */}
      {/* <Contact /> */}
    </div>
  );
}

export default App;
