import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import BacklashSlider from './BacklashSlider'

const Backlash = () => {
  return (
    <div>
      <Navbar2 />
      <BacklashSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'cursive', fontSize:'44px', fontWeight:'600'}}>BACKLASH</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        The thrill with backlash is certain to be unmatched. A sleek tube that accommodates 2 and there you go, plunged into another level of fun.
        <br />
        As you approach the open tunnel, you are driven towards a semi pendulum shaped slide and you just get through the twists and turns of this slide.
        <br />
        As you were just jiggled by one end of the ride and you think, you are done with it. Oh Boy! Then you are plummeted into another end of the slide that is also a semi pendulum that adds to your already rushing blood. Then gently come to a halt but you are left breathless.
        <br />
        Wonder what it's like to be in this Wonderland? Just find it yourself at Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Backlash

