import React from 'react'

const ParkMap = () => {
  return (
    <div>
    <div style={{marginBottom:"40px"}}>
      <h2 className="h1 mb-0" style={{color:'#082B5C',fontFamily:'Comic Sans MS', textAlign:'center', marginTop:"50PX", fontWeight:"600"}}>PARK MAP</h2>
    </div>
    <img style={{width:"100%"}} src="/Images/park-map.png" alt='map' />
    </div>
  )
}

export default ParkMap
