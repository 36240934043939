import React from 'react';

const LandBanner = () => {
  return (
    <div className="banner_wrap in_bn_wrap" style={{ position: "relative", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <video autoPlay loop muted style={{ objectFit: "cover", width: "100%", height: "500px", top: 0, left: 0 }}>
        <source src="/Images/landridemob.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      {/* <div className="overlay" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.2)", zIndex: 2 }}></div>
      <div className="page_hed" style={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column", height: "100%", width: "100%", position: "absolute", top: 0, left: 0, textAlign: "center", zIndex: 3 }}>
        <h2 style={{ textShadow: "2px 2px 5px black", fontSize: "55px", color: '#fff', fontWeight: "600", textAlign: "center", fontFamily:"cursive" }}>Let’s Experience the Magic of Wild Waters! </h2>
      </div> */}
    </div>
  );
}

export default LandBanner;