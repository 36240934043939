import React from 'react'

const Dining = () => {
  return (
    <div>
    <div className="container posts-content">
    <div className="row">
    <div className="col-lg-6">
            <div className="card mb-4">
              <div className="card-body">
                <div className="media mb-3">
                  <div className="media-body ml-3">
                    <h2 className="cardOneHeading" style={{color:'#082B5C',fontFamily:'Comic Sans MS',fontSize:"28px", textAlign:'left', fontWeight:"600"}}>TICKETS & PRICING</h2>
                    <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "4px", borderColor:"#FED001"}} />
                  </div>
                </div>
            
                <p style={{paddingLeft:"10px"}}>
                  <h6 style={{color:'#082B5C',fontFamily:'Times New Roman',fontSize:"20px", textAlign:'left'}}>Note:</h6>
                  <ul style={{textAlign:"left"}}>
                    <li>No Re-Entry.</li>
                    <li>Government Taxes: GST 18% [9% CGST + 9% SGST]</li>
                    <li>Total amount per ticket are rounded off to the nearest rupee.</li>
                    <li>Nylon Swimwear mandatory to utilize water rides.</li>
                    <li>For the safety of our guests, Height and Weight restrictions are applicable for certain rides</li>
                    <li>People under the influence of alcohol or illegal substance are not allowed into the park.</li>
                    <li>State and central government covid19 guidelines to be followed.</li>
                    <li>Senior Citizen : Photo ID with age proof is mandatory for Senior citizen tickets (applicable for guests 60 years and above)</li>
                    <li>College Student Offer : Original college ID needs to be displayed aut the gate during check-in. Applicable only for the age group between 17-22 years & College students in the Academic Year of 2021-22.</li>
                    <li>Children : Child ticket is applicable for kids whose height is between 90 cms and 140 cms. Kids less than 90cm in height would get FREE entry & adult ticket rate is applied for guests above 140cm</li>

                  </ul>
                </p>
              </div>

            </div>
        </div>
        <div className="col-lg-6">
            <div className="card mb-4">
              <div className="card-body">
                <div className="media mb-3">
                  <div className="media-body ml-3">
                    <h2 className="cardOneHeading" style={{color:'#082B5C',fontFamily:'Comic Sans MS',fontSize:"28px", textAlign:'left', fontWeight:"600"}}>DRESS CODE</h2>
                    <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "4px", borderColor:"#FED001"}} />
                  </div>
                </div>
            

                <div style={{display:"flex", justifyContent:"center", position:"relative"}}>
                  <div >
                  <h4 style={{fontSize:"18px",color:'#082B5C', fontWeight:"500", textAlign:"left", paddingLeft:"10px"}}>To provide hygiene & safety dress code is enforced in water park</h4>
                    <img src='/Images/dress_code.jpg' alt='dress-code' />
                    <ul style={{textAlign:"left", paddingLeft:"10px"}}>
                    <li>For your enjoyment, and in the interest of hygiene and safety, a dress code is enforced in the park.</li>
                    <li>Only Polyester or Nylon costumes are allowed in Water Rides.</li>
                    <li>Avoid loose ornaments and spectacles. Footwear not allowed in water rides. Loose footwear and dress not allowed in Adventure and Amusement rides.</li>
                    <li>Do not wear scarfs or shawls while enjoying a ride to ensure your safety and the safety of other visitors.</li>
                    <li>Swimming costumes are available for purchase at our park</li>
                  </ul><br />
                  <h4 style={{fontSize:"18px",color:'#082B5C', fontWeight:"500", textAlign:"left", paddingLeft:"10px"}}>Only polyster or nylon costumes are allowed in water park</h4>
                  </div>

                </div>
              </div>

            </div>
        </div>
    </div>
    </div>
    </div>
  )
}

export default Dining
