import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import MiniDiscoSlider from './MiniDiscoSlider'



const  MiniDisco = () => {
  return (
    <div>
      <Navbar2 />
      <MiniDiscoSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>MINI DISCO</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Mini Disco is a thrilling family kids ride that has a giant padded flying saucer that rotates at tremendous high speed creating bumps, crazy jerks as though they are in a rough sea.
        <br />
        The movements are sometimes slow and sometimes fast, building their anticipation as to what is next.
        <br />
        Ride along and let them experience the twists and turns of this ride as they are bumped, jerked, tilted and a lot more on this crazy, crazy journey of thrill and excitement.

        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default MiniDisco

