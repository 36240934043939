import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import MattRacerSlider from '../WaterRide/MattRacerslider/MattRacerSlider'

const MattRacer = () => {
  return (
    <div>
      <Navbar2 />
      <MattRacerSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>MATT RACER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        As you are pushed down a 240 foot long track, facing your face front from a soaring height of 40 feet, you will experience your blood rushing from your feet to your heart as you are experiencing maximum excitement and thrill.
        <br />
        A sensation of pounding heart as you reach the max speed of 50 km per hour, with your friends racing alongside creating a feel of thrilling competition on the Matt Racer.
        <br />
        You will feel the gust of winds zooming in you as your race downhill along with all the twists and turns that leads you to the victory if reaching the finish line.
        <br />
        A high speed thrill to experience with your friends with all the drops and twists.
        <br />
        The Matt Racer promises you to experience the unexperienced sensation of fun and thrill. 
        <br />
        Challenge your friends to see who will emerge the winner in this Matt Racer at the Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default MattRacer

