import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import CycloneSlider from './CycloneSlider'

const Cyclone = () => {
  return (
    <div>
      <Navbar2 />
      <CycloneSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'cursive', fontSize:'44px', fontWeight:'600'}}>CYCLONE</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        An elating feel of weightlessness as you are pushed down a giant bowl, where you are stuck to the walls defying the concept of gravity. Followed by splashing down into the pool below, once you are done revolving around the bowl.
        <br />
        Your heart pounding in your chest as you are whirling into cyclonic excitement. 
        <br />
        Let the adrenaline take charge of your body as you are plunged into the pool of water below. A refreshing yet exhilarating ride awaits your arrival.
        <br />
        Experience the rush of cyclones where your veins are working to contain the blood rush in them.
        <br />
        An Aquatic adventure opportunity, filled with unlimited whirls and turns exclusively at Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Cyclone

