import React, {useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar2.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


function Navbar2() {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <nav className='main-navbar' style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Link to="/"><img src='/Images/logo-new.png' alt='logo' style={{width:'110px',padding:'10px'}}/></Link>
      <div className='menu' onClick={()=> {
        setMenuOpen(!menuOpen)
      }}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li className="animated fadeInUp wow active animated animated" style={{ visibility: 'visible' }}>
          <NavLink className="menus" to="/waterride">WATER RIDES</NavLink>
        </li>
        <li className="animated fadeInUp wow animated animated" style={{ visibility: 'visible' }}>
          <NavLink className="menus" to="/landride">LAND RIDES</NavLink>
        </li>
        <li className="animated fadeInUp wow animated animated" style={{ visibility: 'visible' }}>
          <NavLink className="menus" to="/thrill">THRILL RIDES</NavLink>
        </li>
        <li className="animated fadeInUp wow animated animated" style={{ visibility: 'visible' }}>
          <NavLink className="menus" to="/kids">KIDS RIDES</NavLink>
        </li>
        <li className="animated fadeInUp wow animated animated" style={{ visibility: 'visible' }}>
          <NavLink className="menus" to="/parkinfo">PARK INFO</NavLink>
        </li>
        <li className="animated fadeInUp wow animated animated" style={{ visibility: 'visible' }}>
          <NavLink className="menus" to="/offers">OFFERS</NavLink>
        </li>
        <li className="animated fadeInUp wow animated animated" style={{ visibility: 'visible' }}>
          <NavLink className="menus" to="https://www.palmexotica.com/" rel="noopener"> RESORT</NavLink>
        </li>

        <li className="animated fadeInUp wow animated animated" style={{ visibility: 'visible' }}>
          <NavLink className="menus" to="/contact">CONTACT</NavLink>
        </li>
        <Box className='nav-btn-1' style={{marginBottom:"10px"}}>
            <Button className='book-now-btn' style={{ backgroundColor: '#0A2254', color: "#F0F8FF", padding: '10px 20px', borderRadius: '18px', fontSize: '13px', fontFamily: 'Comic Sans MS' }}>
              <NavLink to="https://bookings.wildwaters.in/" style={{textDecoration:"none"}} className="book-btn-1">BOOK NOW</NavLink>
            </Button>
        </Box>
      </ul>
      
    </nav>
  );
}

export default Navbar2;
