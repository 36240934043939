import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import ApacheRideSlider from './ApacheRideSlider'



const  ApacheRide = () => {
  return (
    <div>
      <Navbar2 />
      <ApacheRideSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container'>
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>APACHE RIDE</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A fun rotary ride for your little ones, handled by a console. All cockpits of this Apache ride go round uniformly and as they rise and fall, it is a fun experience for your kids.
        <br />
        The kids are buckled in their seats and the ride begins for them to enjoy the wavy ups and downs of Apache Ride.
        <br />
        They will be left in bits of laughter and fun as they go round and round on this ride.
        <br />
        The smiles on their faces will reflect all the excitement and fun they are going through.
        <br />
        Let your kids hop in this ride with their little homies and have the time of their life.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default ApacheRide

