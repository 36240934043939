import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import HurricaneSlider from './HurricaneSlider'

const Hurricane = () => {
  return (
    <div>
      <Navbar2 />
      <HurricaneSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'cursive', fontSize:'44px', fontWeight:'600'}}>HURRICANE</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Imagine you passing some mind blowing twists and turns at a speed of 45 km per hour, into the darkness of a closed tunnel and plummeting out of it only to be greeted by a splash of fresh water.
        <br />
        Hurricane is just the perfect slide for this imagination to be true. Beginning from a dizzying height of the slide to all the twists and turns amidst the excitement and anticipation of the darkness of the closed tunnel.
        <br />
        Experience the ultimate blood rush as you are on a sleek tube that holds 2 people that propels towards a luminous cone shaped funnel. The area where gravity is in-charge, to help you hold on to the sensation of weightlessness, making you feel as you are whirling around the space.
        <br />
        A ride that you will leave breathless but you will still want more of it as you get addicted to the rush of it.
        <br />
        Come experience the exhilaration and refreshment all at once only at Hurricane at the Wild Waters. 
        
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Hurricane

