import React from 'react';
import './ParkInfoTabs.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function ParkInfoTabs() {
  
	return (
		<div>
    <div>
      <h2 className="h1 mb-0" style={{color:'#082B5C',fontFamily:'Comic Sans MS', textAlign:'center', marginTop:"50PX", fontWeight:"600"}}>PARK INFO</h2>
    </div>
		<div class="container posts-content">
    <div class="row">
        <div class="col-lg-6">
            <div class="card mb-4">
              <div class="card-body">
                <div class="media mb-3">
                  <div class="media-body ml-3">
                    <h2 className="cardOneHeading" style={{color:'#082B5C',fontFamily:'Comic Sans MS',fontSize:"28px", textAlign:'left', fontWeight:"600"}}>PARK TIMINGS</h2>
                    <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "4px", borderColor:"#FED001", marginTop:"8px"}} />
                  </div>
                </div>
            
                <p style={{textAlign:"left", paddingLeft:"10px"}}>
                    <strong style={{color:'#082B5C'}}>Monday - Friday :</strong> 11:00 AM to 6:00 PM<br />
                    <strong style={{color:'#082B5C'}}>Saturday - Sunday/Public Holidays :</strong> 11:00 AM to 7:00 PM<br />
                    <strong style={{color:'#082B5C'}}>Water Rides close by :</strong> 6:00 PM on all days<br />
                    Child Height between 90 to 140 cm & Senior Citizen aged 60 and above.*<br />
                    <strong style={{color:'#082B5C'}}>Note:</strong> After hours start from 3:30PM on Weekends and Public Holidays.
                    </p>
                {/* <a href="javascript:void(0)" class="ui-rect ui-bg-cover" style={background-image: url('https://bootdey.com/img/Content/avatar/avatar3.png');}></a> */}
              </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="card mb-4">
              <div class="card-body">
                <div class="media mb-3">
                  <div class="media-body ml-3">
                    <h2 className="cardOneHeading" style={{color:'#082B5C',fontFamily:'Comic Sans MS',fontSize:"28px", textAlign:'left', fontWeight:"600"}}>DINING</h2>
                    <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "4px", borderColor:"#FED001", marginTop:"8px"}} />
                  </div>
                </div>
            
                <p style={{textAlign:"left", paddingLeft:"10px"}}>
                    <h5 style={{fontSize:"18px",color:'#082B5C', fontWeight:"500"}}>Food Express</h5>
                    <span>Food Express offers an appealing and mouth-watering range of food options to its visitors 
                    at its multi-cuisine Food Court.Not just this, it has got soft beverage counters all around to 
                    quench your thirst with a choice of drinks along with ice-cream and quick bites.</span><br />
                    <h5 style={{fontSize:"18px",color:'#082B5C',fontWeight:"500"}}>Island Cafe</h5>
                    Grab a quick bite while you are having fun at Water Park<br />
                    <h5 style={{fontSize:"18px",color:'#082B5C',fontWeight:"500"}}>Adventure Cafe</h5> 
                    Add some bite sized deliciousness into the day you spend laughing, walking, running, jumping, 
                    and having fun and enjoying yourself. Pick a snack of your choice at our Adventure cafe located 
                    between our Amusement and Adventure park

                </p>
                {/* <a href="javascript:void(0)" class="ui-rect ui-bg-cover" style={background-image: url('https://bootdey.com/img/Content/avatar/avatar3.png');}></a> */}
              </div>
            </div>
        </div>
    </div>
</div>
		</div>
	);
}
export default ParkInfoTabs;