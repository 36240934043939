import React from 'react'

const ContactBanner = () => {
  return (
    // <div style={{width:"100%"}}>
    //   <img src='/Images/banner.jpeg' alt='contact-banner' style={{height:"450px", width:"100%"}} />
    // </div>
    <div className="banner_wrap in_bn_wrap" style={{backgroundImage: "url(/Images/banner.jpeg)", height: "500px", position: "relative", backgroundSize: "cover", width: "100%"}}>
      <div className="page_hed" style={{display:"flex", justifyContent:"center", alignItems:'center', flexDirection:"column", height:"100%"}}>
        <h2 style={{textShadow:"2px 2px 5px black", fontSize:"50px", color:'#fff', fontWeight:"600", fontFamily:"Comic Sans MS"}}>CONTACT US </h2>
      </div>
    </div>
  )
}

export default ContactBanner
