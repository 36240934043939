import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import OctopusSlider from './OctopusSlider'


const  Octopus = () => {
  return (
    <div>
      <Navbar2 />
      <OctopusSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>OCTOPUS</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A fun family ride that impersonates an octopus. Oh! An octopusy ride is to take you on a circulating ride that will make you wonder if you are on a real octopus in a deep sea.
        <br />
        The tentacles of this octopus are the cabins, hydraulically attached to the octopus that takes the rider on a whirlwind of highs and lows.
        <br />
        Let this ride take you on an exhilarating journey of an octopus wandering in a rough sea.
        <br />
        Take your family, friends or your closed circle, to experience this circulating wonder.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default Octopus

