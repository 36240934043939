import React from 'react'
import Footer from '../Footer/Footer'
import TwisterSlider from './TwisterSlider'
import Navbar2 from '../Navbar/Navbar2'

const Twister = () => {
  return (
    <div>
      <Navbar2 />
      <TwisterSlider />
      <div className='welcome-container' style={{ paddingTop: '60px', paddingBottom: '60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain", position: "relative", transition: "all 1.0s ease" }}>
        <div className='water-ride-container' style={{ opacity: 1, transition: "opacity 0.5s ease-in-out" }}>
          <h3 className="welcomeHeading" style={{ color: '#082B5C', fontFamily: 'cursive', fontSize: '44px', fontWeight: '600',position: "relative", transition: "all 1.0s ease" }}>TWISTER</h3>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p className="cardOneParagraph2" style={{ color: '#082B5C', fontFamily: 'Montserrat', fontSize: '18px', display: "flex", justifyContent: "center", alignItems: "center", width: '600px' }}>
              Twister to twist your ways of experiencing fun!
              Standing 40 feet tall, a closed tunnel body slide spreading for about an impressive 325 foot long area.
              <br />
              With each twist along the path, each curve building the rush of adrenaline, it is an ultimate experience to have altogether.
              <br />
              Riders enveloped in the twists and turns of this slide, with a climaxing splashdown the slide in a refreshing pool of water, it is each thrill seekers ultimate source of thrill.
              <br />
              From a heart twisting drop to an exhilarating splashdown, it leaves every rider wanting for more.
              <br />
              So, hold on tight as you are about Be Twisssted!! at the Twister in the Wild Waters.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Twister