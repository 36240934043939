import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import PendulumSlider from './PendulumSlider'



const  Pendulum = () => {
  return (
    <div>
      <Navbar2 />
      <PendulumSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>PENDULUM</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Pendulum is an ultimate thrill ride which makes a pendulum motion along the vertical arm suspended at a soaring height of 40 feet above ground. Attached to the base of this arm is a circular gondola.
        <br />
        Riders are seated in the gondola, facing outwards. When the ride cycle starts, the gondola begins to rotate clockwise and then anti-clockwise on a timer while the pendulum arm begins to swing through an arc at high speeds, maxing out between 180° and 200°.
        <br />
        The sense of weightlessness makes the riders feel like traveling in space.
        <br />
        Come along and experience your world being swinged as a pendulum with the Pendulum at the Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default Pendulum

