import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import FrogHopperSlider from './FrogHopperSlider'



const  FrogHopper = () => {
  return (
    <div>
      <Navbar2 />
      <FrogHopperSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>FROG HOPPER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A small sized bouncy ride for your kids in which the kids are elevated and dropped as though they are bouncing.
        <br />
        An ideal family fun ride that brings the thrill of a frog jump as they get the feel of touching the sky and coming down all over again. With the ride design resembling the frog’s motion, this ride is a fun adventure like no other.
        <br />
        Climb into the frogs that are carved to mimic the natural frog. Let them be hopped as the ride comes into action.
        <br />
        Each jump provides a surreal sensation, giving them tons of exciting memories to take back home. It is a refreshing, amazing and a safe experience for your kids.
        <br />
        Let the spirit of these frogs give them a bouncy experience that will leave your kids in a burst of laughter and joy.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default FrogHopper

