import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import TunnelSlider from './TunnelSlider'

const Tunnel = () => {
  return (
    <div>
      <Navbar2 />
      <TunnelSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'cursive', fontSize:'44px', fontWeight:'600'}}>TUNNEL</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Imagine being plummeted from a soaring height of 40 feet into a series of open and closed tunnels, with your better half in a 2 seater tube.
        <br />
        Heart racing fast, blood rushing through the veins, the building anticipation, a more than real sensation of thrill.
        <br />
        A crazy adventure for all the thrill lovers, to go through a series of darkness and sunshine, fun real life transitions added by the cylindrical elements of the slide that makes you go Woohoo!!
        <br />
        Let yourself immerse in the pool of fun and thrill, and just let your inner child take charge and be fearless.
        <br />
        Come and experience the Tunnel at the Wild Waters!
        
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Tunnel

