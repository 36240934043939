import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import TsunamiSlider from './TsunamiSlider'



const  Tsunami = () => {
  return (
    <div>
      <Navbar2 />
      <TsunamiSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>TSUNAMI</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Tsunami is a 20 seater thrilling attraction of Wild Waters, where the riders are seated in their rotating seats.
        <br />
        As the ride begins, it starts to rotate from the sidearms it is attached to. Wait there is more, thai is not the only rotation, your seats start to swivel around along with the ride.
        <br />
        You experience the twists of the chairs as well as the rotational motion of the ride adds to the thrill.
        <br />
        You will feel your heart throbbing with excitement and fun.
        <br />
        Experience thrill like never before with Tsunami at Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default Tsunami

