import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import Slider360 from './Slider360'



const  Thrill360 = () => {
  return (
    <div>
      <Navbar2 />
      <Slider360 />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>360</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        360 is a circular ride that rotates you 360°, as if you are in a real life clock except you are the arms of the clock going round and round.
        <br />
        It will rotate clockwise and anticlockwise unless you complete a few circles of this ride.
        <br />
        The thrill is unimaginable, the circular motion will make your brain go numb, while being completely safe.
        <br />
        Let go of any fear and hop on for your world to rotate a complete 360.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default Thrill360

