import React from 'react';
// import './FamilyRide.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function KidsRideTwo() {
	return (
		<div>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div className="container" style={{width:'80%'}}>

                <div className="row position-relative mt-n1-9">
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/mini-pirate-ship.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className="mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Mini Pirate Ship</h3>
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/minipirateship" className="text-white">About Mini Pirate Ship</Link></h3>
                                        <p className="text-white mb-0">A pendulating ship like swing for your kids taking them to the heights of the sky and dropping down making their heart go fast!</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                        <Link to="/minipirateship" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/mini-disco-1.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Mini Disco</h3>

                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/minidisco" className="text-white">About Mini Disco</Link></h3>
                                        <p className="text-white mb-0">A crazy thrill ride for your kids, where the kids are seated on a flying saucer, rotating and creating buzzing movements, some crazy jerks, twisty bumps and a lot more.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/minidisco" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/ocean-walk.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info" >
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Ocean Walk</h3>
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/oceanwalk" className="text-white">About Ocean Walk</Link></h3>
                                        <p className="text-white mb-0">Ocean Walk is a fun ride for kids based on the mystical sea and the cabins designed like beautiful mermaid, dolphins, cute conchs and other sea animals.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/oceanwalk" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
export default KidsRideTwo;
