import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import CarouselSlider from './CarouselSlider'


const Carousel = () => {
  return (
    <div>
      <Navbar2 />
      <CarouselSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>CAROUSEL</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Carousal, a timeless beauty, a beloved family ride at Wild Waters.
        <br />
        This classic attraction is perfect for riders of every age, who love casual fun. Kids above 90 cm are allowed to ride on this timeless beauty. Carousel is one of the most cherished, traditional rides.
        <br />
        As you go on this Merry-go-round, you will experience the magic of this ride.
        <br />
        The beautified horses that gallop as they go, the royal depiction of the chariots, everything adorned with royalty as you are gracefully embracing the spins on this ride.
        <br />
        You will feel a magical joy as you will be surrounded by soothing soft music and fanciful melodies.
        <br />
        Get your loved ones to relive the nostalgia and capture some beautiful new memories to cherish in forever at the Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Carousel

