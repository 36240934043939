import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import OceanWalkSlider from './OceanWalkSlider'



const  OceanWalk = () => {
  return (
    <div>
      <Navbar2 />
      <OceanWalkSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>OCEAN WALK</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        An ocean themed ride for kids to be amazed by mystical appearances on the ride.
        <br />
        They are accompanied by some beautiful mermaids, dolphins and other sea animals, as the cabins are designed using all these sea creatures.
        <br />
        The ride begins and the cabins start to rotate around, they then start to move up and down as if merged by the waves of the ocean
        <br />
        Your kids will feel like diving into the sea and playing with them, offering a great source of fun for kids.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default OceanWalk

