import React from 'react'
import LandRideThrillOne from './LandRideThrillOne'
import LandRideThrillTwo from './LandRideThrillTwo'
import LandRideThrillThree from './LandRideThrillThree'

const ThrillLand = () => {
  return (
    <div style={{backgroundImage: "url(/Images/bgImg-4.jpeg)", backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center'}}>
      <LandRideThrillOne />
      <LandRideThrillTwo />
      <LandRideThrillThree />
    </div>
  )
}

export default ThrillLand
