import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { NavLink } from 'react-router-dom';
import './../HomeCards/HomeCardOne.css'; // Import your CSS file for styling
import { Typography } from '@material-ui/core';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CallIcon from '@mui/icons-material/Call';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const LatestOffer = () => {
  const [inViewRef, inView] = useInView({
    triggerOnce: false, // Trigger multiple times while scrolling
    rootMargin: '-50px 0px',
  });
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true); // Set animate to true when the section is in view
    } else {
      setAnimate(false); // Reset animate when the section goes out of view
    }
  }, [inView]); // Add inView as a dependency for the useEffect hook

  return (
    <div ref={inViewRef} className={`revealing-section ${animate ? 'animate' : ''}`} style={{backgroundImage: "url(/Images/bgImg2.jpeg)", backgroundSize: "cover",position: "relative"}}>
      
      <div className="column">
        {/* Image */}
        <img src="/Images/latestOfferImg.jpeg" alt="LandImg"  />
      </div>
      <div className="column">
        {/* Content */}
        <div>
        <Typography variant="h6" style={{fontFamily:'Comic Sans MS', color:"#0A2254", fontSize:"34px", fontWeight:'900'}}>Please don't hesitate to reach out</Typography>
                  <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "2px", borderColor:"#FED001"}} />
                  <br />
                  <Typography variant="body1">
                  <p className="animated fadeInUp wow" style={{fontFamily:'Comic Sans MS',color:"#0A2254", fontSize:"20px", }}>SY.No.125, MASANIGUDA VILLAGE <br /> SHANKARPALLI, <br /> RANGA REDDY DIST.<br /> HYDERABAD – 501203.</p>
                  <p className='form-emails'><MailOutlineIcon sx={{color:"#0A2254", padding:"0 5px 0 0"}} /><a href="mailto:hos@wildwaters.in" style={{textDecoration:"none", color:"#0A2254", fontSize:"20px", fontFamily:'Comic Sans MS'}}>hos@wildwaters.in</a></p>
                  <p className='form-emails'><MailOutlineIcon sx={{color:"#0A2254",padding:"0 5px 0 0"}} /><a href="mailto:info@wildwaters.in" style={{textDecoration:"none", color:"#0A2254", fontSize:"20px", fontFamily:'Comic Sans MS'}}>info@wildwaters.in</a></p>
                  <p><CallIcon sx={{padding:"0 5px 0 0",color:'#0A2254', size:"20px"}} /><a href="tel:04048511111" style={{padding:"0 0 0 0px",color:'#0A2254',textDecoration: 'none', fontFamily:'Comic Sans MS', fontSize:"20px"}}>04048511111</a>, <br/> <a href="tel:7799288111" style={{padding:"0 0 0 23px",color:'#0A2254', textDecoration: 'none', fontFamily:'Comic Sans MS', fontSize:"20px"}}>7799288111</a>, <br /> <a href="tel:7799055111" style={{padding:"0 0 0 23px",color:'#0A2254',textDecoration: 'none', fontFamily:'Comic Sans MS', fontSize:"20px"}}>7799055111</a></p>
                  <p style={{color:"#0A2254", fontSize:"20px", fontFamily:'Comic Sans MS'}}><AccessTimeIcon sx={{padding:"0 5px 0 0",color:'#0A2254', size:"20px"}} /> Sales Office Timings: 10 AM To 6.30 PM</p><br />

                  <Box className='nav-btn-1' style={{marginBottom:"10px"}}>
                    <Button className='book-now-btn' style={{ backgroundColor: '#0A2254', color: "#F0F8FF", padding: '10px 20px', borderRadius: '18px', fontSize: '13px', fontFamily: 'Comic Sans MS' }}>
                    <NavLink to="https://bookings.wildwaters.in/" style={{textDecoration:"none"}} className="book-btn-1">BOOK NOW</NavLink>
                    </Button>
                  </Box>
                            {/* <h3>Social Media</h3> */}
                            <Typography variant="h6" style={{fontFamily:'Comic Sans MS', color:"#0A2254", fontSize:"20px"}}>SOCIAL MEDIA</Typography>
                            <div className="social-icons">
                                {/* <li> */}
                                    <a href="https://www.instagram.com/accounts/login/?next=/wildwaters_themepark/" class="animated fadeInLeft wow" rel="noopener">
                                      <InstagramIcon sx={{color:'#0A2254',margin:'4px'}} />
                                    </a>
                                {/* </li> */}
                                {/* <li> */}
                                    <a href="https://www.facebook.com/wildwatersthemepark" className="animated fadeInLeft wow" rel="noopener">
                                    <FacebookRoundedIcon sx={{color:'#0A2254',margin:'4px'}} />
                                    </a>
                                {/* </li> */}
                                {/* <li> */}
                                  <a href="https://twitter.com/WildWaters_" className="animated fadeInLeft wow" rel="noopener">
                                    <TwitterIcon sx={{color:'#0A2254',margin:'4px'}} />
                                  </a>
                                {/* </li> */}
                                {/* <li> */}
                                    <a href="https://www.youtube.com/channel/UC83IUPSCmbUQbYHxBLGEpEA" className="animated fadeInLeft wow" rel="noopener">
                                    <YouTubeIcon sx={{color:'#0A2254',margin:'4px'}} />
                                    </a>
                                {/* </li> */}
                            </div>
                  </Typography>
                  
        </div>
      </div>
      
    </div>
  );
}

export default LatestOffer;