import React from 'react'
import HeroSection from '../HeroSection/HeroSection'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import ParadiseBeachSlider from '../WaterRide/ParadieBeachSlider/ParadiseBeachSlider'

const ParadiseBeach = () => {
  return (
    <div>
      <Navbar2 />
      <ParadiseBeachSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>PARADISE BEACH</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Feel the rush of varying speed waves as you are propelled towards the shoreline.
        Paradise Beach is a wave pool spread across an impressive 16,000 square feet area,
        giving you an unforgettable beach experience without you having to go to a beach.
        <br />
        Imagine an afternoon at the sandy beach with lush coconut groves peeking from around
        and tropical landscapes all around… A luscious tropical paradise experience…Ahhh! 
        Sounds so refreshing to the ears.
        It is the exact immersive and exhilarating experience as it sounds. 
        <br />
        Paradise Beach teleports you directly to a tropical paradise.
        <br/>
        Looking to catch a wave or to just soak up the sun while unwinding
        all the stress with friends and family, Wild Waters have the ultimate solution of fun and relaxation for you.
        <br/>
        Come and experience the ultimate beach experience without
        visiting an actual beach, exclusively available at Wild Waters.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default ParadiseBeach
