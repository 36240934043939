import { Carousel } from 'react-bootstrap';
import React,{useState} from 'react';

function WaterSlider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
    <Carousel data-bs-theme="dark" activeIndex={index} onSelect={handleSelect} interval={5000}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./Images/waterSlide1.jpeg"
          alt="Third slide"
          style={{width:'100%', height:'600px', objectFit:"cover", position:'center'}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./Images/waterSlide2.jpeg"
          alt="Third slide"
          style={{width:'100%', height:'500px', objectFit:"cover", position:'center'}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./Images/waterSlide3.jpeg"
          alt="Third slide"
          style={{width:'100%', height:'600px', objectFit:"cover", position:'center'}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./Images/waterSlide4.jpeg"
          alt="Third slide"
          style={{width:'100%', height:'600px', objectFit:"cover", position:'center'}}
        />
      </Carousel.Item>
      
    </Carousel>
    </div>
  );
}

export default WaterSlider;