import React from 'react'

const TowerImg = () => {
  return (
    <div>
    <div className="container posts-content">
    <div className="row">
    <div className="col-lg-12">
            <div className="card " style={{border:"none"}}>
              <div className="card-body" >
                <div className="media">
                  <div className="media-body ml-3">
                    <img style={{width:"100%"}}  src="/Images/tower.png" alt="tower1" />
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
    </div>
  )
}

export default TowerImg
