import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import TornadoSlider from '../WaterRide/TornadoSlider/TornadoSlider'

const Tornado = () => {
  return (
    <div>
      <Navbar2 />
      <TornadoSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>TORNADO</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A whirlwind of excitement, a thrill of twists and turns combined together in the Tornado at the Wild Waters.
        <br />
        Tornado stands at an elevation of 40 feet with a staggering length of 300 feet for you to feel the excitement with each approaching second.
        <br />
        Hold on to yourself as you make your way through an open body slide enhanced with all the twists and turns to make you feel the presence of each nerve in your body at a breakneck speed.
        <br />
        As you approach the finale of the ride, let your body feel the anticipation building as you finally splash down in a water filled pool.
        <br />
        Let the tornado twist and turn you while making unforgettable memories at the Wild Waters. 
        
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Tornado

