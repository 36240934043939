import React from 'react'

const StudentOffer = () => {
  return (
    <div style={{marginTop:"50px"}}>
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600', marginTop:"20px"}}>OFFERS</h3>
      <div className="container posts-content">
    <div className="row">
    <div className="col-lg-6">
            <div className="card mb-4">
              <div className="card-body">
                <div className="media mb-3">
                  <div className="media-body ml-3">
                    <h2 className="cardOneHeading" style={{color:'#082B5C',fontFamily:'Comic Sans MS',fontSize:"28px", textAlign:'left', fontWeight:"600"}}>STUDENT OFFER</h2>
                    <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "4px", borderColor:"#FED001", marginTop:"8px"}} />
                  </div>
                </div>
                <div >
                  <img src='/Images/student_offer_post.jpg' alt='studentoffer' />
                </div>
              </div>

            </div>
        </div>
        <div className="col-lg-6">
            <div className="card mb-4">
              <div className="card-body">
                <div className="media mb-3">
                  <div className="media-body ml-3">
                    <h2 className="cardOneHeading" style={{color:'#082B5C',fontFamily:'Comic Sans MS',fontSize:"28px", textAlign:'left', fontWeight:"600"}}>GROUP OFFER</h2>
                    <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "4px", borderColor:"#FED001", marginTop:"8px"}} />
                  </div>
                </div>
            

                <div style={{display:"flex", justifyContent:"center", position:"relative"}}>
                  <div >
                    <img src='/Images/img3.jpg' alt='group-offer' />
                  </div>

                </div>
              </div>

            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default StudentOffer
