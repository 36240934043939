import React from 'react'
import FamilyRide from './FamilyRide'
import FamilyRideTwo from './FamilyRideTwo'
import FamilyRideThree from './FamilyRideThree'
import FamilyRideFour from './FamilyRideFour'

const WaterFamilyRides = () => {
  return (
    <div style={{backgroundImage: "url(/Images/bgImg5.jpeg)", backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center'}}>
      <FamilyRide />
      <FamilyRideTwo />
      <FamilyRideThree />
      <FamilyRideFour />
    </div>
  )
}

export default WaterFamilyRides
