import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import TagadaDiscoSlider from './TagadaDiscoSlider'


const  TagadaDisco = () => {
  return (
    <div>
      <Navbar2 />
      <TagadaDiscoSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>TAGADA DISCO</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Disco Tagada is not your regular disco, it is a thrilling family ride that has a giant padded flying saucer that rotates at tremendous high speed creating bumps, crazy jerks as though you are on a rough sea.
        <br />
        The movements are sometimes slow and sometimes fast, building your anticipation as to what is next.
        <br />
        Come along and experience the twists and turns of this ride as you are bumped, jerked, tilted and a lot more on this crazy, crazy journey of thrill and excitement.

        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default TagadaDisco

