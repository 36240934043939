import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import HarakiriSlider from './HarakiriSlider'

const Harakiri = () => {
  return (
    <div>
      <Navbar2 />
      <HarakiriSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'cursive', fontSize:'44px', fontWeight:'600'}}>HARAKIRI</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Harakiri, a full package of adventure in itself. A ride that takes you uphill and plunges you downhill on a 2 seater float slide, for you to feel a momentum of defied gravity. Then you are slowly carried away, as far as you will go.
        <br />
        Just feel the blood pumping in your veins as you take on this humongous ride and just slide through the curves and turns of it.
        <br />
        Take your homie with you on an experience of refreshment accompanied with loads of adrenaline rushing through the body.
        <br />
        Harakiri will certainly make you go from Oh I See!, To, Oh My God!
        <br />
        Let the rush of this adventure take over the whole of you and immerse in the goodness of Harakiri, at Wild Waters.
        
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Harakiri

