import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CallIcon from '@mui/icons-material/Call';
import './Footer.css'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
// import { MDBFooter } from 'mdb-react-ui-kit';


function Footer() {
  return (
    <footer className='footer'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" style={{fontFamily:'Comic Sans MS'}}>ABOUT US</Typography>
          <Typography variant="body1" style={{fontFamily:'Comic Sans MS'}}>Wild Waters is a unique and fresh take on amusement & entertainment, while just a very short & scenic drive from the city. We’re perfecting the art of family-friendly fun by gearing up with the best rides for different age groups!</Typography>
          <div style={{marginTop:'20px', display:'flex', justifyContent:'left'}}>
          <Typography>
            <Stack direction="row" spacing={2}>
            <div style={{display:'flex', justifyContent:'center'}}>
          {/* <button type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'600', fontSize:'14px', fontFamily:'Montserrat', padding:"10px"}}>EXPLORE</button> */}
          </div>
            </Stack>
          </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" style={{fontFamily:'Comic Sans MS'}}>QUICK LINKS</Typography>
          <Typography variant="body1">
              <div>
              <Link to="/waterride"><Button style={{color:'#F0F8FF', fontFamily:'Comic Sans MS'}}>Water rides</Button></Link>
              </div>
              <div>
              <Link to="/landride"><Button style={{color:'#F0F8FF', fontFamily:'Comic Sans MS'}}>Land rides</Button></Link>
              </div>
              <div>
              <Link to="/parkinfo"><Button style={{color:'#F0F8FF', fontFamily:'Comic Sans MS'}}>Park info</Button></Link>
              </div>
              <div>
              <Link to="/offers"><Button style={{color:'#F0F8FF', fontFamily:'Comic Sans MS'}}>Offers</Button></Link>
              </div>
              <div>
              <Link to="https://www.palmexotica.com/"><Button style={{color:'#F0F8FF', fontFamily:'Comic Sans MS'}}>Resort</Button></Link>
              </div>
              <div>
              <Link to="/contact"><Button style={{color:'#F0F8FF', fontFamily:'Comic Sans MS'}}>Contact</Button></Link>
              </div>

          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" style={{fontFamily:'Comic Sans MS'}}>TIMINGS</Typography>
          <Typography variant="body1">
          <p className="animated fadeInUp wow" style={{fontFamily:'Comic Sans MS'}}> <span>Monday </span> 11:00 AM to 6:00 PM <br /> 
                                {/* <span style="vertical-align:top;">Tuesday </span>   <em style="display: inline-block;max-width: 270px;">Closed (It will be open on all days from next week)</em> <br> */}
                                <span>Tuesday</span> 11:00 AM to 6:00 PM <br />
                                <span>Wednesday </span>  11:00 AM to 6:00 PM <br />
                                <span>Thursday </span>   11:00 AM to 6:00 PM <br />
                                <span>Friday </span>   11:00 AM to 6:00 PM <br />
                                <span>Saturday </span>  11:00 AM - 7:00 PM <br />
                                <span>Sunday </span>  11:00 AM - 7:00 PM <br />
                                <span>Public Holidays </span>  11:00 AM - 7:00 PM <br />
                                Water Rides close by 6:00 PM on all days <br />
                                Note: After hours start from 3:30PM on Weekends and Public Holidays.
                                </p>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" style={{fontFamily:'Comic Sans MS'}}>LOCATION</Typography>
          <Typography variant="body1">
          <p className="animated fadeInUp wow" style={{fontFamily:'Comic Sans MS'}}>Sy.No.125, MASANIGUDA VILLAGE <br /> SHANKARPALLI, <br /> RANGA REDDY DIST.<br /> HYDERABAD – 501203.</p>
          <p><CallIcon sx={{padding:"0 10px 0 0",color:'#F0F8FF'}} /><a href="tel:04048511111" style={{padding:"0 0 0 0px",color:'#F0F8FF',textDecoration: 'none', fontFamily:'Comic Sans MS'}}>04048511111</a>, <br/> <a href="tel:7799288111" style={{padding:"0 0 0 23px",color:'#F0F8FF', textDecoration: 'none', fontFamily:'Comic Sans MS'}}>7799288111</a>, <br /> <a href="tel:7799055111" style={{padding:"0 0 0 23px",color:'#F0F8FF',textDecoration: 'none', fontFamily:'Comic Sans MS'}}>7799055111</a></p>

                            <Typography variant="h6" style={{fontFamily:'Comic Sans MS'}}>SOCIAL MEDIA</Typography>
                            <div className="social-icons">

                                    <a href="https://www.instagram.com/accounts/login/?next=/wildwaters_themepark/" class="animated fadeInLeft wow" rel="noopener">
                                      <InstagramIcon sx={{color:'#F0F8FF',margin:'4px'}} />
                                    </a>

                                    <a href="https://www.facebook.com/wildwatersthemepark" className="animated fadeInLeft wow" rel="noopener">
                                    <FacebookRoundedIcon sx={{color:'#F0F8FF',margin:'4px'}} />
                                    </a>

                                  <a href="https://twitter.com/WildWaters_" className="animated fadeInLeft wow" rel="noopener">
                                    <TwitterIcon sx={{color:'#F0F8FF',margin:'4px'}} />
                                  </a>

                                    <a href="https://www.youtube.com/channel/UC83IUPSCmbUQbYHxBLGEpEA" className="animated fadeInLeft wow" rel="noopener">
                                    <YouTubeIcon sx={{color:'#F0F8FF',margin:'4px'}} />
                                    </a>
                            </div>
          </Typography>
        </Grid>
        <hr className='hr-line' style={{ width: '100%', margin: '0 auto', display: "flex", textAlign:"center", padding:"2px", borderWidth: "4px", borderColor:"#FED001", marginTop:"20px"}} />
        <Grid item container style={{justifyContent:"center", marginTop:"10px"}}>
          <Typography variant="body2" >
            &copy; {new Date().getFullYear()} Designed & Developed by Virtual Take.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;

