import React from 'react';
// import './FamilyRide.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function LandRideThrillTwo() {
	return (
		<div>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div className="container" style={{width:"80%"}}>

                <div className="row position-relative mt-n1-9">
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/mixer-2.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className="mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Mixer</h3>
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/mixer" className="text-white">About Mixer</Link></h3>
                                        <p className="text-white mb-0">It mixes you up and down, rotating you your seats while the arm of the ride revolves 360°</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/mixer" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/tsunami-1.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Tsunami</h3>
                                {/* <span className="font-weight-600 text-secondary">Photographer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/tsunami" className="text-white">About Tsunami</Link></h3>
                                        <p className="text-white mb-0">Circling in your seat while your ride is rotating 360°, it is like a rotation on rotation.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/tsunami" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/tst.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info" >
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>360</h3>
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/360" className="text-white">About 360</Link></h3>
                                        <p className="text-white mb-0">You will feel like the arms of a clock going 360 around the clock.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/360" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
export default LandRideThrillTwo;

