import React from 'react'
import ParkInfoTabs from './ParkInfoTabs'
import Dining from './Dining'
import ParkMap from './ParkMap'
import LatestOffer from '../LatestOffer/LatestOffer'
import Footer from '../Footer/Footer'
import Safety from './Safety'
import TowerImg from './TowerImg'
import ParkSafetyMeasures from './ParkSafetyMeasures'
import ParkTerms from './ParkTerms'
import Navbar2 from '../Navbar/Navbar2'
import ParkInfoBannerVideo from './ParkInfoBannerVideo'

const ParkInfo = () => {
  return (
    <div>
      <Navbar2 />
      <ParkInfoBannerVideo />
      <ParkInfoTabs />
      <Dining />
      <Safety />
      <TowerImg />
      <ParkSafetyMeasures />
      <ParkTerms />
      <ParkMap />
      <LatestOffer />
      <Footer />
    </div>
  )
}

export default ParkInfo
