// import React, {useEffect, useState} from 'react'
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";





// const NewWelcomeSection = () => {
//   const [loaded, setLoaded] = useState(false);

//   useEffect(() => {
//     // Set loaded to true after a short delay to trigger the transition
//     const timeout = setTimeout(() => {
//       setLoaded(true);
//     }, 100);

//     // Clear the timeout on component unmount
//     return () => clearTimeout(timeout);
//   }, []);

//   return (
//     <div className="container posts-content">
//     <div className="row">
//     <div className="col-lg-6">
//             <div className="card " style={{border:"none"}} >
//               <div className="card-body">
//                 <div className="media mb-3">
//                   <div className="media-body ml-3">
//                   </div>
//                 </div>
//                 <div style={{display:"flex", justifyContent:"center", position:"relative", overflow:"hidden", width:"100%"}}>
//                     <img src='/Images/Wildwatersdesc.jpg' alt='water-Slide' style={{ width: '100%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', height:'100%', transition: 'transform 1s ease-in-out',transform: loaded ? 'translateX(0)' : 'translateX(-100%)' }} />
//                 </div>
//               </div>
//             </div>
//         </div>
//     <div className="col-lg-6">
//             <div className="card" style={{border:"none", backgroundColor:"#082B5C", height:"100%"}}>
//               <div className="card-body" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
//                 <div className="media mb-3">
//                   <div className="media-body ml-3" >
//                     {/* <h2 className="cardOneHeading" style={{color:'#fff',fontFamily:'Times New Roman', textAlign:'center', fontSize:"28px"}}>Let’s Experience the magic of Wild Waters!</h2><br /> */}
//                     <h2 className="cardOneHeading" style={{color:'#fff',fontFamily:'sans-serif', textAlign:'center', fontSize:"24px"}}>Dive in with us for an Exhilarating Experience</h2>
//                     <br />
//                     <hr className='hr-line' style={{ width: '40px', margin: '0 auto', display: "flex", textAlign:"center", padding:"5px", borderWidth: "4px", borderColor:"#FED001"}} />
//                   </div>
//                 </div>
//                 <p style={{color:'#fff', fontFamily:'Montserrat',fontSize:"20px", textAlign:'center'}}>
//                 Wild Waters Water and Amusement Theme Park, a wonderland of adventures and fun, spreading across 30 acres of tropical landscape that encompasses a larger than life Water and Amusement Park. Breaking all records in Hyderabad with their 50+ land and water rides.
//                 A Hyderabad Wonder-land, the Wild Waters water park, an endless tunnel of fun and excitement that will always lead you to experience that adrenaline rush to a new level.
//                 Let loose of your inner child at the Wild Waters, which is among one of the best rated Water parks in Hyderabad.<br />
//                 Let the fun unleash, let the adventure take the front wheel… <br />
//                 At Wild Waters, let the Funnnn Beginnnnnn…
//                 </p>
//                 {/* <div style={{display:'flex', justifyContent:'center', marginTop:'15px'}}>
//                   <button type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#fff', color:"#082B5C", borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', padding:'10px'}}>EXPLORE</button>
//                 </div> */}
//               </div>
//             </div>
//         </div>
//     </div>
// </div>

//   )
// }

// export default NewWelcomeSection


// ++++++++++++++++++ new code +++++++++++++++++++++++

import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
// import { Link } from 'react-router-dom';
import './WelcomeSection.css'; 

const RevealingSection = () => {
  const [inViewRef, inView] = useInView({
    triggerOnce: false, // Trigger multiple times while scrolling
    rootMargin: '-50px 0px',
  });
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true); 
    } else {
      setAnimate(false); 
    }
  }, [inView]); 

  return (
    <div ref={inViewRef} className={`revealing-section ${animate ? 'animate' : ''}`} style={{backgroundColor:'#082b5c'}}> 
      <div className="column">
        {/* Image */}
        <img src="/Images/newWelcome.jpeg" alt="welcomeImg" style={{borderRadius:"25px 0px 25px 0px"}} />
      </div>
      <div className="column">
        {/* Content */}
        <h2 style={{color:'#ffff',fontFamily:'Comic Sans MS', textAlign:'left', fontSize:'30px', fontWeight:'600', padding:'10px'}}>Dive in with us for an Exhilarating Experience</h2>
        <hr className='hr-line' style={{ width: '40px', margin: '0 auto', display: "flex", textAlign:"center", padding:"3px", borderWidth: "4px", borderColor:"#fff"}} />
        <p style={{fontSize:'18px', color:'#fff', textAlign:"left", paddingLeft:"10px"}}>
        Wild Waters Water and Amusement Theme Park, a wonderland of adventures and fun, spreading across 30 acres of tropical landscape that encompasses a larger than life Water and Amusement Park. Breaking all records in Hyderabad with their 50+ land and water rides. A Hyderabad Wonder-land, the Wild Waters water park, an endless tunnel of fun and excitement that will always lead you to experience that adrenaline rush to a new level. Let loose of your inner child at the Wild Waters, which is among one of the best rated Water parks in Hyderabad.
Let the fun unleash, let the adventure take the front wheel…
At Wild Waters, let the Funnnn Beginnnnnn…</p>
      </div>
    </div>
  );
}

export default RevealingSection;