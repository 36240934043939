import React from 'react';

const ThrillBannerVideo = () => {
  return (
    <div className="banner_wrap in_bn_wrap" style={{ position: "relative", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <video autoPlay loop muted style={{ objectFit: "cover", width: "100%", height: "500px", top: 0, left: 0 }}>
        <source src="/Images/thrillridemob.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <h2 style={{ textShadow: "2px 2px 5px black", fontSize: "50px", color: '#fff', fontWeight: "600" }}>Let’s Experience the magic of Wild Waters! </h2> */}
      
      
    </div>
  );
}

export default ThrillBannerVideo;