import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import SkyDropSlider from './SkyDropSlider'



const  SkyDrop = () => {
  return (
    <div>
      <Navbar2 />
      <SkyDropSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>SKY DROP</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Sky drop is an exciting ride at Wild Waters that will surely blow your mind and keep your blood rush in check.
        <br />
        As you are seated and buckled in your seat. You are slowly and gradually lifted up to a soaring height of 40 meters. As you reach the highest elevation you will think this is as easy as it came up.
        <br />
        You are soon to be amazed. As soon as you reach the highest of this ride, you will feel you are coming down at the same pace as you went up but Oh Boy! 
        <br />
        You are suddenly dropped down with such a high pace that your brain will take a few mins to process the drop.
        <br />
        Experience the thrill of this sky drop as it can be the most exciting source of thrill. 
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default SkyDrop

