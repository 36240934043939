import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import FlyingCarSlider from './FlyingCarSlider'



const  FlyingCar = () => {
  return (
    <div>
      <Navbar2 />
      <FlyingCarSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>FLYING CAR</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        The flying car is a kids ride which consists of a car having wheels that roll along on a track.
        <br />
        Once the ride starts the car slides on the track with lots of swirls and twirls on the track along with all the twists and turns at high speed making it adventurous and exciting for your kids.
        <br />
        This ride keeps your kids on the edge and builds their anticipation as though what is next, what is the next turn, what is the next swirl and making them feel as if they are riding a flying car in a rough tornado.
        <br />
        Hop them in on the flying cars and let them experience the rush of excitement and fun.

        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default FlyingCar

