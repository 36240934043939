import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import FreeFallSlider from '../WaterRide/FreeFallSlider/FreeFallSlider'

const FreeFall = () => {
  return (
    <div>
      <Navbar2 />
      <FreeFallSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>FREE FALL</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Hold on tight as you experience the rush of air in your ears and the thrill of the Free Fall pulsate through your veins with an exceptional sensation of free falling.
        <br />
        Towering height of 40 feet and a journey down a 220 foot long track, to send chills of excitement through your body.
        <br />
        You will definitely feel the adrenaline rushing in your body every thrilling second when you will speed down the ride with 50 km per hour of speed.
        <br />
        A combination of lightning speed and breathtaking height, it is definitely an adventure for all the thrill seekers.
        <br />
        To have a sensational feel of freefalling, visit Wild Waters and experience the ultimate Free Fall. 
        
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default FreeFall

