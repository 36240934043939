import React from 'react';
// import './FamilyRide.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function KidsRideOne() {
	return (
		<div>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div className="container" style={{width:'80%', paddingTop:"30px"}}>
                <div className="section-heading mb-4">
                    <h2 className="h1 mb-0" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>KIDS RIDES</h2>
                </div>
                <div className="row position-relative mt-n1-9">
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/jumbo-train.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className="mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Jumbo Train</h3>
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/jumbotrain" className="text-white">About Jumbo Train</Link></h3>
                                        <p className="text-white mb-0">An electric miniature train for your kids that travels along an oval shaped track giving an experience of an actual train with added fun and excitement.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/jumbotrain" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/flying-bees-1.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info">
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Rotary Bees</h3>
                                {/* <span className="font-weight-600 text-secondary">Photographer</span> */}
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/rotarybees" className="text-white">About Rotary Bees</Link></h3>
                                        <p className="text-white mb-0">A fun wavy, bees like merry go round for your kids. Giving them the feel of beautiful butterflies flying in a garden full of flowers.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>

                                        <Link to="/rotarybees" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9">
                        <div className="team-style1 text-center">
                            <img src="/Images/apache-ride-1.jpg" className="border-radius-5" alt="..." style={{width:"100%", height:"350px", objectFit:"cover", padding:'10px'}}/>
                            <div className="team-info" >
                                <h3 className=" mb-1 h4" style={{color:"#082B5C", fontFamily:'Comic Sans MS', fontWeight:"600"}}>Apache Ride</h3>
                            </div>
                            <div className="team-overlay">
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-cell align-middle">
                                        <h3><Link to="/apacheride" className="text-white">About Apache Ride</Link></h3>
                                        <p className="text-white mb-0">Apache Ride is a fun rotary ride operated by console where all cockpits go round uniformly, rise and fall giving a fun experience.</p>
                                        <ul className="social-icon-style1" style={{display:"flex", justifyContent:"center"}}>
                                        <Link to="/apacheride" type="submit" style={{display:'flex', alignItems:'center',backgroundColor:'#FED001', borderRadius:'20px', fontWeight:'500', fontFamily:'Montserrat', color:"#082B5C",padding:"10px"}}>EXPLORE</Link>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
export default KidsRideOne;
