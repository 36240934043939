import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import RotaryBeesSlider from './RotaryBeesSlider'



const  RotaryBees = () => {
  return (
    <div>
      <Navbar2 />
      <RotaryBeesSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container'>
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>ROTARY BEES</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Beginning from a soaring height of 40 feet, Boomerang is a ride where you are seated on a tube that holds 2, that slides in a closed tunnel followed by a sudden drop into a large boomerang shaped slide.
        <br />
        As you go, you experience multiple feelings at once, from weightlessness to blood rush and everything in between.
        As you are shooted up and down the slide with a speed of 45 km per hour and then slowly slides down into the large tub of water, you are bound to experience the elation of excitement.
        <br />
        Come and experience the boomerang for yourself and you will know how true exhilaration feels like, only at Wild Waters.

        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default RotaryBees

