import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import MiniPirateShipSlider from './MiniPirateShipSlider'



const  MiniPirateShip = () => {
  return (
    <div>
      <Navbar2 />
      <MiniPirateShipSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>MINI PIRATE SHIP</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A mini open seated ship-like, family kids ride attached to a pendulum to take them on a swing from front to back at a soaring angle of 200° at maximum speeds, giving them a feel of being a pirate, sailing in a violent sea.
        <br />
        Ask your kids to hold on to their seat as they are about to be on a voyage of thrill and excitement.
        <br />
        Let the blood in your veins soar as you touch the heights of the sky and then feel the sudden drop in the altitude as the ship takes a plunge down and then it repeats again until their senses are conquered by the thrill of this ride.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default MiniPirateShip

