import React from 'react'
import HeroSection from '../HeroSection/HeroSection'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import VoyageSlider from '../WaterRide/VoyageSlider/VoyageSlider'

const Voyage = () => {
  return (
    <div>
      <Navbar2 />
      <VoyageSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>VOYAGE</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Voyage, a long raft slide that can accommodate 4 on the raft, to set on an exhilarating journey of this thrilling raft slide that measures about 500 feet in length.
        <br />
        Hold your heart as you plunge down a slide that is filled with high speed turns and twisty loops.
        <br />
        Buckle up your nerves because this is an experience of your lifetime as you feel an intense push of blood through your brain.
        <br />
        The excitement aggravates as you navigate through each loop of the slide. An unforgettable experience to go through with your group of friends.
        <br />
        Join hands with your fellows as you will need to hold onto each other to help you contain your excitement and the thrill. 
        <br />
        Let’s embark on the Voyage at the Wild Waters!
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Voyage

