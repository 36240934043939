import React from 'react'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import RapidsSlider from '../WaterRide/RapidsSlider/RapidsSlider'

const Rapids = () => {
  return (
    <div>
      <Navbar2 />
      <RapidsSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgWater.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>RAPIDS</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        Let your heart rush rapidly on the Rapids!
        A thrilling adventure of 240 long raft slide, perfect for families and friend groups that takes you on a swooshing raft that accommodates up to 4 people.
        <br />
        Fuel in with the excitement as you go up and down the slide, battling with the twists and turns of thrill.
        <br />
        As you come closer to the end you will see that you are being welcomed by a rapid gush of water as the ride carries you forward as you keep floating on the water tub.
        <br />
        A family pack of entertainment that gives you only laughter and thrill in return.
        <br />
        Experience the Rapids yourself with your friends and family and get an opportunity to have a memory box of fun for a lifetime with Wild Waters. 
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Rapids

