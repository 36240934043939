import React from 'react'

const ParkTerms = () => {
  return (
    <div>
    <div className="container posts-content">
    <div className="row">
    <div className="col-lg-12">
            <div className="card mb-4" style={{border:"none"}}>
              <div className="card-body" >
                <div className="media mb-3">
                  <div className="media-body ml-3">
                    <h2 className="cardOneHeading" style={{color:'#082B5C',fontFamily:'Comic Sans MS',fontSize:"28px", textAlign:'left', fontWeight:"600"}}>PARK TERMS & CONDITIONS</h2>
                    <hr className='hr-line' style={{ width: '40px', margin: '0', display: "flex", textAlign:"left", padding:"3px", borderWidth: "4px", borderColor:"#FED001", marginTop:"8px"}} />
                  </div>
                </div>
            
                <p>
                  <ul style={{textAlign:"left", paddingLeft:"10px"}}>
                    <li>We do not recommend Thrill rides to guests who are physically not fit, pregnant or suffering from neck or back pain, heart ailments, BP and vertigo etc.</li>
                    <li>Height, weight & safety criteria are to be strictly followed. Park staff have the right to refrain people from the activity if they do not fit the criteria.</li>
                    <li>Strictly follow the instructions of Park staff for your safety. The park will not be responsible for any mishaps due to non-adherence of any of the safety instructions.</li>
                    <li>Due to high wear and tear of the activities, weather and for reason whatsoever, the Park management shall not be held responsible for non-availability or down time of any of the rides.</li>
                    <li>Waiting time varies from 5 minutes to 45 minutes depending on the crowd. Please wait for your turn.</li>
                    <li>Nylon or Polyester Swim Wear is mandatory to utilize any water rides.</li>
                    <li>Children below height of 90 cm are allowed only at the Pirates Cove, Aqua Play, Wave pool and Rain disco. Children between 90 to 140 cm are not allowed in Thrill water rides. Adult supervision mandatory</li>
                    <li>Depth of the pool varies from 1.4 to 2.2 mtrs on the deep side while the waves are on. Those who cannot swim should use the shallow area. Diving into the pool is not permitted.</li>
                    <li>For Hygiene reasons, all persons are requested to take a shower before using the water rides.</li>
                    <li>Wash your feet before entering the pool.</li>
                    <li>Avoid loose ornaments and spectacles. Footwear not allowed in water rides.</li>
                    <li>Recommend People with long hair to wear a Swimming cap.</li>
                    <li>Exit the water slide immediately after the ride ends.</li>
                    <li>The Management reserves the right of admission to any person into the Park and to request any guest to leave the Park at any moment without prior notice and without assigning any reason whatsoever, and the guest shall be bound to leave when requested to do so.</li>
                    <li>These rules and regulations are subject to change any time without notice.</li>
                    <li>First Aid available at park entrance.</li>
                    <li>Smoking is allowed only in designated area near Cafe Adventure.</li>
                  </ul>
                </p>
              </div>

            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default ParkTerms
