import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar2 from '../../../Navbar/Navbar2'
import ZipLineSlider from './ZipLineSlider'



const  ZipLine = () => {
  return (
    <div>
      <Navbar2 />
      <ZipLineSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>ZIP LINE</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        A high thrill recreation activity consisting of a cable stretched between points of different elevations.
        <br />
        The rider is strapped on to a safety harness and attached to a pulley and zips by gravity from an impressive 50 foot high tower along a 700 foot long line.
        <br />
        It gives the rider a feel of weightlessness yet being safe.
        <br />
        You can feel the sensation of blood rush from your head to toe. The feeling of flying in the sky is unmatched.
        <br />
        As you reach the end of the zipline, you will wish for more… for another round and Why Not!
        <br />
        Weight restrictions apply.
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default ZipLine

