import React from 'react'
import Navbar from '../Navbar/Navbar'
import ContactBanner from './ContactBanner'
import LatestOffer from '../LatestOffer/LatestOffer'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
// import ContactSection from './ContactSection'
import ContactMap from './ContactMap'

const Contact = () => {
  return (
    <div>
      <Navbar2 />
      <ContactBanner   />
      <LatestOffer />
      {/* <ContactSection /> */}
      <ContactMap />
      <Footer />
    </div>
  )
}

export default Contact
