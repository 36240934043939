import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import WaveSurferSlider from './WaveSurferSlider'


const  WaveSurfer = () => {
  return (
    <div>
      <Navbar2 />
      <WaveSurferSlider />
      <div className='welcome-container' style={{paddingTop:'60px', paddingBottom:'60px', backgroundImage: "url(/Images/bgLand.jpeg)", backgroundSize: "contain",position: "relative"}}>
        <div className='water-ride-container' >
        <h3 className="welcomeHeading" style={{color:'#082B5C', fontFamily:'Comic Sans MS', fontSize:'44px', fontWeight:'600'}}>WAVE SURFER</h3>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <p className="cardOneParagraph2" style={{color:'#082B5C', fontFamily:'Montserrat', fontSize:'18px', display:"flex", justifyContent:"center", alignItems:"center", width:'600px'}}>
        An ideal surfing experience brought for you to Hyderabad.
        <br />
        It is not everyday that you can experience what surfing feels like, right! Do not worry, we have got you covered, go through an actual surf like simulation in our Wave Surfer.
        <br />
        A ride that gives you the wonderful experience of surfing but the catch is that you do not have to visit an actual beach to surf.
        <br />
        This simulator also has inflatable padded flooring in case there is a harsh wave and you get off your surfboard.
        <br />
        Ohh! The joy and thrill of surfing provides you and your closed one an unmatched adventurous experience.
        <br />
        So take along your homies as you get ready to Surrfff!
        </p>
        </div>
    </div>
    </div>
    <Footer />
    </div>
  )
}
export default WaveSurfer

