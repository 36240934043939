import React from 'react'
import Navbar2 from '../Navbar/Navbar2'
import ThrillRide from '../WaterRide/ThrillRide/ThrillRide'
import Footer from '../Footer/Footer'
import LatestOffer from '../LatestOffer/LatestOffer'
import ThrillLand from '../LandRide/ThrillRide/ThrillLand'
import ThrillBannerVideo from './ThrillBannerVideo/ThrillBannerVideo'

const Thrill = () => {
  return (
    <div>
      <Navbar2 />
      <ThrillBannerVideo />
      <ThrillRide />
      <ThrillLand />
      <LatestOffer />
      <Footer />
    </div>
  )
}

export default Thrill
